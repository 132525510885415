import {
  Flex,
  SimpleGrid,
  Heading,
  Button,
  ButtonGroup,
  useToast,
  Box,
  FormControl,
  FormLabel,
  Input,
  Center,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useState } from "react";
import ContentCard from "../../nav/ContentCard";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { CheckIQScoreSchema, CheckIQScoreType } from "./schema";
import { CertificateModal } from "./CertificateModal";
import { TheLastSpellingBeeReCaptcha } from "the-last-spelling-bee-re-captcha";
import { Background } from "../../../common/background/Background";
import { Score } from "../../../types/score-types";
import { api } from "../../../redux-action/api.service";
import { ScoreCard } from "./ScoreCard";
import { theLastSpellingBeeReCaptchaKey } from "../../../common/config";


export const CheckIQScore = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCaptchaVerify, setIsCaptchaVerify] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [scores, setScores] = useState<Score[]>([]);
  const [playerScore, setPlayerScore] = useState<Score>();
  const itemsPerPage = 10;

  const toast = useToast();

  const {
    handleSubmit,
    register,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(CheckIQScoreSchema),
  });

  const email = watch('email');

  const onSubmit = async (email: CheckIQScoreType) => {
    try {
      const response = await api.service(`game-scores/email/${email.email}`).find();
      setScores(response);
    } catch (error: any) {
      toast({
        title: "Error",
        description:
          "An error occurred while fetching your Memory IQ score. Please try again.",
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    } 
  };
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <ContentCard bg={"transparent"}>
      <Background />
      <Heading textAlign="center" mb={5} mt={20} color={"white"}>
        Check Your Memory IQ Score
      </Heading>
      <Center>
        <Box
          as={"form"}
          mt={0}
          onSubmit={handleSubmit(onSubmit)}
          w={"70%"}
          bg={"white"}
          p={5}
          rounded={"lg"}
        >
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Email
            </FormLabel>
            <Input
              type="email"
              {...register("email")}
              placeholder="Your email address"
              id="email"
              isInvalid={!!errors.email}
              required
              mb={4}
            />
          </FormControl>
          <TheLastSpellingBeeReCaptcha
            reCaptchaKey={theLastSpellingBeeReCaptchaKey}
            questionType="RANDOM"
            onVerifyCaptcha={(result: any) => {
              setIsCaptchaVerify(!result);
            }}
          />
          {!isCaptchaVerify ? (
            <Button
              type="submit"
              w="100%"
              h="45"
              mb="20px"
              color="white"
              mt="20px"
              colorScheme="blue"
              isLoading={isSubmitting}
            >
              Submit
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Center>
      {!scores.length  ? (
        <Alert status="info" mt={5}>
          <AlertIcon />
          {email ? `No IQ certificate found for ${email}!` : 'Enter the email that you used for your Memory IQ test to check your Memory IQ score'}
        </Alert>
      ) : (
        <>
          <Center>
            <SimpleGrid
              columns={{ base: 1, md: 1, lg: 1, xl: 1, "2xl": 1 }}
              spacing={5}
              borderRadius="lg"
              p={{ base: 0, md: 0, lg: 0, xl: 5, "2xl": 5 }}
              width={{
                base: "100%",
                md: "100%",
                lg: "100%",
                xl: "70%",
                "2xl": "70%",
              }}
            >
              {scores?.map((score, index) => (
                <ScoreCard
                  key={index}
                  score={score}
                  onViewCertificate={(score) => {
                    setPlayerScore(score);
                    setIsModalOpen(true);
                  }}
                />
              ))}
            </SimpleGrid>
          </Center>
          <Flex justify="center" mt={4}>
            <ButtonGroup size="sm" isAttached variant="outline">
               {Array.from({
                length: Math.ceil(scores.length / itemsPerPage),
              }).map((_, index) => (
                <Button
                  key={index}
                  onClick={() => paginate(index + currentPage)}
                  cursor="pointer"
                  colorScheme="blue"
                  size="lg"
                >
                  {index + currentPage}
                </Button>
              ))} 
            </ButtonGroup>
          </Flex>
          <CertificateModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            score={playerScore ?? ({} as Score)}
          />
        </>
     )}
    </ContentCard>
  );
};
