import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
} from "@chakra-ui/react";
import { CertificateView } from "../game-play/result-chart/CertificateView";
import { Score } from "../../../types/score-types";


type CertificateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  score: Score;
};

export const CertificateModal: React.FC<CertificateModalProps> = ({
  isOpen,
  onClose,
  score,
}) => {
  const totalQuestions = score?.game?.numberOfQuestions ?? 0;
  const scoreValue = score?.score ?? 0;
  const correctAnswers = Math.floor(scoreValue / 5);
  const incorrectAnswers = totalQuestions - correctAnswers;

  const data = [
    { name: "Correct", value: correctAnswers },
    { name: "Incorrect", value: incorrectAnswers },
  ];
  const COLORS = ["#2f855a", "#c53030"];

  if (!score) {
    <Spinner />;
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={"#2b6cb0"}>
          {`${score?.firstName} ${score?.lastName}`}
          's Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CertificateView
            score={score ?? ({} as Score)}
            correctAnswers={correctAnswers}
            totalQuestions={totalQuestions}
            data={data}
            COLORS={COLORS}
          />
          
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
