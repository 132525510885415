import { default as SyntaxHighlighter } from "react-syntax-highlighter";
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';

type HighlighterProps = {
  content: string;
  language?: string;
};

export const Highlighter = ({ content, language = 'javascript' }: HighlighterProps) => {
  return (
    // @ts-ignore
    <SyntaxHighlighter
      language={language}
      style={monokaiSublime}
    >
      {content}
    </SyntaxHighlighter>
  );
};
