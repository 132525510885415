import React from "react";
import * as yup from 'yup';
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  useToast,
  Icon,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Box,
} from "@chakra-ui/react";
import { useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup'; 
import { FaPlus } from "react-icons/fa";
import { useApiService } from "../../redux-action/api.service";
import { useReCaptcha } from '../../context/ReCaptchaContext';

export const CreateNewApiKey = () => {
  const api = useApiService();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const toast = useToast();
  const { refreshReCaptchas } = useReCaptcha();
 
  const ApiKeyValidationSchema = yup.object().shape({
    domainName: yup.string().required('Domain is required'),
  });
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({ 
    resolver: yupResolver(ApiKeyValidationSchema),
  });


  
const onSubmitHandler = async (data: any) => {
   try {
    await api.service('re-captcha').create(data);
      
    toast({
      title: "API Key created successfully",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
    
    onClose();
    await refreshReCaptchas();
   } catch (error: any) {
    toast({
      title: "Error creating API key",
      description: error.response?.data?.message || error.message,
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
   }
};


  return (
    <>
      <Button
        leftIcon={<Icon as={FaPlus} />}
        onClick={onOpen}
        colorScheme="blue"
        size="md"
        rounded="md"
        shadow="sm"
        _hover={{ transform: 'translateY(-1px)', shadow: 'md' }}
        transition="all 0.2s"
      >
        Create New API Key
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="lg">Create New API Key</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text mb={4} color="gray.600">
              After creating an API key, you can begin utilizing our Re-captcha API.
              Please ensure you store the key securely as it won't be shown again.
            </Text>
            
            <Box as="form" onSubmit={handleSubmit(onSubmitHandler)}>
              <FormControl isRequired isInvalid={!!errors.domainName}>
                <FormLabel>Domain Name</FormLabel>
                <Input
                  {...register("domainName")}
                  placeholder="e.g., example.com"
                  focusBorderColor="blue.400"
                />
              </FormControl>

              <Button
                mt={6}
                w="full"
                colorScheme="blue"
                type="submit"
                isLoading={isSubmitting}
                loadingText="Creating..."
              >
                Create API Key
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
