import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type QuestionNumberState = {
  currentQuestionIndex: number;
};
const initialState: QuestionNumberState = {
  currentQuestionIndex: 0,
};

const questionNumberSlice = createSlice({
  name: "questionNumber",
  initialState,
  reducers: {
    setCurrentQuestionIndex: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.currentQuestionIndex = action.payload;
    },
    resetQuestionNumber: () => initialState
  },
});

export const {
  setCurrentQuestionIndex,
  resetQuestionNumber,
} = questionNumberSlice.actions;
export const selectCurrentQuestionIndex = (state: RootState) => state.questionNumber.currentQuestionIndex;
export default questionNumberSlice.reducer;
