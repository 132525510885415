import { createContext, useContext, ReactNode, useState, useCallback } from 'react';
import { Game } from '../types/game-type';
import { useApiService } from '../redux-action/api.service';

type GamesContextType = {
  games: Game[];
  isLoading: boolean;
  fetchGames: (userId: string) => Promise<void>;
  refreshGames: () => Promise<void>;
}

const GamesContext = createContext<GamesContextType | undefined>(undefined);

export const GamesProvider = ({ children }: { children: ReactNode }) => {
  const [games, setGames] = useState<Game[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState<string>('');
  const api = useApiService();

  const fetchGames = useCallback(async (newUserId: string) => {
    try {
      setIsLoading(true);
      setUserId(newUserId);
      const response = await api.service(`games/user/${newUserId}`).find();
      setGames(response.sort((a: Game, b: Game) => (b.id ?? 0) - (a.id ?? 0)));
    } catch (error) {
      console.error("Failed to fetch games:", error);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const refreshGames = useCallback(async () => {
    if (userId) {
      await fetchGames(userId);
    }
  }, [userId, fetchGames]);

  return (
    <GamesContext.Provider value={{ games, isLoading, fetchGames, refreshGames }}>
      {children}
    </GamesContext.Provider>
  );
};

export const useGames = () => {
  const context = useContext(GamesContext);
  if (context === undefined) {
    throw new Error('useGames must be used within a GamesProvider');
  }
  return context;
}; 