import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Score } from "../../types/score-types";

export type ResultState = {
  score: Score | null;
  isLoading: boolean;
  error: string | null;
};
const initialState: ResultState = {
  score: null,
  isLoading: false,
  error: null,
};

const gameResultSlice = createSlice({
  name: "gameResult",
  initialState,
  reducers: {
    setResultData: (
      state,
      action: PayloadAction<{
        score: Score;
      }>,
    ) => {
      state.score = action.payload as unknown as Score;
    },
    resetResult: () => initialState,
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setResultData,
  resetResult,
  setLoading,
  setError,
} = gameResultSlice.actions;
export default gameResultSlice.reducer;
