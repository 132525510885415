

export const SOUND_LIST = [
    "correct/1",
    "correct/2",
    "correct/3",
    "correct/4",
    "correct/5",
    "wrong/1",
    "wrong/2",
    "wrong/3",
    "wrong/4",
    "wrong/5",
    "wrong/6",
    'pop',
    'click'
  ] as const;
  
  export type SoundList = typeof SOUND_LIST[number];
  
