import { categoryOPtions } from "../../home/types";
import { QUESTION_LEVEL_LABELS } from "../../../types/questions.type";

export const validateQuestion = (
  question: string | string[],
) => {
  if (!checkIfNumber(question[0])) {
    return question.toString().replace(/,/g, "");
  }
  return question.toString().replace(/,/g, "-");
};

export const checkIfNumber = (char: string) => {
  if (parseInt(char) > 0) {
    return true;
  }
  return false;
};

export const validateNumberQuestion = (stringChar: string) =>{
    let regExp= /^(?=.*[a-zA-Z])(?=.*[0-9])/;
    const check = regExp.test(removeSpecialCharacters(stringChar));
    if(check){
      return stringChar.split("-");
    }

    if(stringChar.toLowerCase() !== stringChar.toUpperCase()){
      const removeWhiteSpace = (str: string) => str.trim();
      return removeWhiteSpace(stringChar);
    }
    return stringChar.split("-");
}
export const getLevel = (questionType: string) => {
  return QUESTION_LEVEL_LABELS[questionType as keyof typeof QUESTION_LEVEL_LABELS] || questionType;
};
export const getCategory = (value: string) => {
  const option = categoryOPtions.find(option => option.value === value);
  return option ? option.label : null;
};

export const removeSpecialCharacters = (str: string) => {
    return str.replace(/[^\w\s]/gi, '');
}

export const getInviteLink = (inviteId: string) =>{
  const currentURL = window.location.href;
  const url = new URL(currentURL);
  return `${url.origin}/spell/${inviteId}`;
}
export const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
  // localStorage.removeItem('isApiKeyNavigatePage');
  // localStorage.clear();
}
export const isExpired = (date: any) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return date < today;
}

export const calculateIQScore = (numberOfQuestions:number, score: number) =>{
  if(!numberOfQuestions || !score){
    return 0;
  }
  const defaultScore = 5;
  const maximumMarks = numberOfQuestions * defaultScore;
  const PercentageMarks = (score/maximumMarks) * 100;

  if(maximumMarks < 1){
    return 0;
  }
  return PercentageMarks.toFixed(0); 

}
export const evaluateGameCompleteTime = (gameCompleteTime: string) =>{
  const finalGameTime = JSON.stringify(gameCompleteTime);
  const finalTimeData = finalGameTime.replace(/\\/g, "");
  const resultTime = finalTimeData.substring(1, finalTimeData.length-1);
  return JSON.parse(resultTime);
}
export const calculatePoints = (numberOfQuestions:number, gameScore:number, gameTimeCompleted:string) => {
  const correctAnswers = gameScore/5;
  const defaultScore = 1000; // Base points for each correct answer
  const maxResponseTime = 5 * 60; // Maximum time allowed in seconds (5 minutes)

  const gameCompleteTime = evaluateGameCompleteTime(gameTimeCompleted);
  if(!gameCompleteTime.gameCompleteTime || gameScore < 1){
    return 0;
  }
  const [hours, minutes, seconds] = gameCompleteTime.gameCompleteTime.split(":").map(Number);
  const completeTime = { hours, minutes, seconds };
  const timeTaken = completeTime.hours * 3600 + completeTime.minutes * 60 + completeTime.seconds;

  const timeBonus = Math.max(maxResponseTime - timeTaken, 0) / maxResponseTime;
  const maximumScore = numberOfQuestions * defaultScore;
  const score = correctAnswers * defaultScore;
  const totalPoints = Math.floor(score + timeBonus * maximumScore);
  return numberWithCommas(gameScore > 0 ? totalPoints: 0);
};
export const  numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const getExDate = (exDate: string) => {
  if (!exDate) {
    return "";
  }
  const currentDate = new Date(exDate);
  currentDate.setDate(currentDate.getDate() + 1);
  return currentDate.toISOString().split("T")[0];
};

