import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {DefaultNav} from "./apps/nav/DefaultNav";
import { ChakraProvider } from "@chakra-ui/react";
import { DefaultFooter } from "./apps/nav/DefaultFooter";
import AppRoutes from "./AppRoutes";
import { useState } from "react";
import initialTheme from './theme/theme'; 
import { GamesProvider } from './context/GamesContext';
import { ReCaptchaProvider } from './context/ReCaptchaContext';
import { useGame } from "./hooks/use-game";

function App() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const { user: gameUser } = useGame();
  return (
    <ReCaptchaProvider>
      <GamesProvider>
        <ChakraProvider theme={currentTheme} >
          <Router>
            <DefaultNav setTheme={setCurrentTheme}/>
            <AppRoutes/>
            {!gameUser && <DefaultFooter />}
          </Router>
        </ChakraProvider>
      </GamesProvider>
    </ReCaptchaProvider>
  );
}

export default App;