import { FC, useEffect, useState } from "react";
import {
  Box,
  Heading,
  Table,
  Text,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
  Button,
  Link,
  Icon,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import ContentCard from "../nav/ContentCard";
import { useApiService } from "../../redux-action/api.service";
import { Score } from "../../types/score-types";
import { FaCrown } from "react-icons/fa";

export const GameResult: FC = () => {
  const { gameId } = useParams();
  const api = useApiService();
  const [score, setScore] = useState<Score[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchScore = async () => {
      const response = await api.service(`game-scores/game/${gameId}`).find();
      const sortedData = (response || [])
        .slice()
        .sort(
          (a: { score: number }, b: { score: number }) => b.score - a.score,
        );
      setScore(sortedData);
    };

    if (gameId) {
      try {
        setIsLoading(true);
        fetchScore();
      } catch (err) {
        console.log(err);
        setError(
          err instanceof Error ? err : new Error("Failed to fetch score"),
        );
      } finally {
        setIsLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  if (isLoading || !score) {
    return <Spinner />;
  }
  if (error) {
    return (
      <Text color="red.500">Error loading game results: {error.message}</Text>
    );
  }
  return (
    <ContentCard>
      <Box borderRadius="15px" px="0px" display="flex">
        <Heading fontSize="32px" mb={5} textAlign={"center"}>
          Results
        </Heading>
      </Box>
      <Text fontWeight={600} mb={4} fontSize={18}>
        M-IQ Name: <Text as={"span"}>{score[0]?.game?.name}</Text>
      </Text>
      <Text fontWeight={600} mb={4} fontSize={18}>
        Category: <Text as={"span"}>{score[0]?.game?.gameCategory}</Text>
      </Text>
      <TableContainer bg={"white"} p={9}>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th></Th>
              <Th fontSize={18}>User</Th>
              {/* <Th fontSize={18}>Points</Th> */}
              <Th fontSize={18}>M-IQ</Th>
              <Th fontSize={18}>Time Completed</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {score &&
              score.map((game: Score, index: any) => (
                <Tr key={index} boxShadow={"md"} top={9}>
                  <Td>
                    <ReactCountryFlag countryCode={game.country} />
                  </Td>
                  <Td>{`${game.user?.firstName} ${game.user?.lastName}`}</Td>
                  {/* <Td>
                    {calculatePoints(
                      game.game?.numberOfQuestions ?? 0,
                      game.score,
                      game.timeCompleted,
                    )}
                  </Td> */}
                  <Td>
                    {Number(game.memoryIQScore) >= 85 && (
                      <Icon
                        as={FaCrown}
                        color="yellow.400"
                        w={5}
                        h={5}
                        mt={-3}
                        transform="translateY(-8px)"
                      />
                    )}
                    {game.memoryIQScore ?? ''}
                  </Td>
                  <Td>{game.timeCompleted ?? ''}</Td>
                  <Td>
                    <Link
                      href={`/auth/result-details/${game.id}/${game.game?.gameCategory}`}
                      color="#2b6cb0"
                      fontSize={{ base: 14, sm: 17 }}
                      me="10px"
                    >
                      <Button h={8} colorScheme="blue" variant="outline">
                        Details
                      </Button>
                    </Link>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </ContentCard>
  );
};
