import {
  Box,
  Heading,
  Text,
  Code,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  UnorderedList,
  ListItem,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { Highlighter } from "./Highlighter";

export const DocumentationSection = () => {
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const basicUsageCode = `
import { TheLastSpellingBeeReCaptcha } from 'the-last-spelling-bee-re-captcha';

const MyComponent = () => {
  return (
    <TheLastSpellingBeeReCaptcha
      reCaptchaKey={'your-TheLastSpellingBee-key'}
      onVerifyCaptcha={(result) => {
        console.log('Result: ', result);
      }}
    />
  );
};`;

  const errorHandlingCode = `
import { TheLastSpellingBeeReCaptcha } from 'the-last-spelling-bee-re-captcha';

const MyComponent = () => {
  const handleVerification = (result) => {
    if (result.status === 200) {
      console.log('Verification successful');
    }
  };

  const handleError = (error) => {
    console.error('ReCaptcha error:', error);
    // Handle error appropriately
  };

  return (
    <TheLastSpellingBeeReCaptcha
      reCaptchaKey="your-api-key"
      onVerifyCaptcha={handleVerification}
      onError={handleError}
      refreshOnVerify={true}
    />
  );
};`;

  const advancedUsageCode = `
import { TheLastSpellingBeeReCaptcha } from 'the-last-spelling-bee-re-captcha';

const AdvancedComponent = () => {
  return (
    <TheLastSpellingBeeReCaptcha
      reCaptchaKey="your-api-key"
      questionType="COMPLEX"
      wordLength={5}
      refreshOnVerify={true}
      onVerifyCaptcha={(result) => {
        if (result.status === 200) {
          // Handle success
          console.log('Verification successful');
        } else {
          // Handle failure
          console.error('Verification failed');
        }
      }}
      onError={(error) => {
        console.error('Error:', error);
      }}
    />
  );
};`;

  return (
    <Box bg="white" p={8} rounded="xl" shadow="md">
      <Heading size="lg" mb={6} color="gray.700">
        📌 Documentation
      </Heading>
      <Divider mb={6} />

      <Tabs variant="enclosed" colorScheme="blue">
        <TabList>
          <Tab>📌 Overview</Tab>
          <Tab>⚙️ Props</Tab>
          <Tab>🎯 Examples</Tab>
          <Tab>🔄 Question Types</Tab>
          <Tab>⚠️ Error Handling</Tab>
          <Tab>📡 API Response</Tab>
        </TabList>
        <TabPanels>
          {/* Overview Section */}
          <TabPanel>
            <VStack align="start" spacing={4}>
              <Text fontSize="lg" fontWeight="medium">
                A lightweight and customizable reCAPTCHA alternative that
                enhances security through memory-based verification.
              </Text>
              <Box
                width="100%"
                borderWidth={1}
                borderColor={borderColor}
                p={4}
                rounded="md"
                textAlign="left"
              >
                <Heading size="sm" mb={2}>
                  Key Features:
                </Heading>
                <UnorderedList spacing={2}>
                  <ListItem>
                    Multiple question types for varied challenges
                  </ListItem>
                  <ListItem>Customizable word length and complexity</ListItem>
                  <ListItem>Real-time verification and error handling</ListItem>
                  <ListItem>Automatic refresh options</ListItem>
                  <ListItem>TypeScript support</ListItem>
                </UnorderedList>
              </Box>
              <Box width="100%" textAlign="left">
                <Heading size="sm" mb={2}>
                  Basic Implementation:
                </Heading>
                <Highlighter content={basicUsageCode} />
              </Box>
            </VStack>
          </TabPanel>

          {/* Props Section */}
          <TabPanel>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Prop Name</Th>
                  <Th>Type</Th>
                  <Th>Required</Th>
                  <Th>Default</Th>
                  <Th>Description</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Code>reCaptchaKey</Code>
                  </Td>
                  <Td>string</Td>
                  <Td>Yes</Td>
                  <Td>-</Td>
                  <Td>Your API key for authentication</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Code>questionType</Code>
                  </Td>
                  <Td>string</Td>
                  <Td>No</Td>
                  <Td>'CHARACTERS'</Td>
                  <Td>
                    Type of question (CHARACTERS | NUMBERS | RANDOM | COMPLEX)
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Code>wordLength</Code>
                  </Td>
                  <Td>number</Td>
                  <Td>No</Td>
                  <Td>3</Td>
                  <Td>Length of the challenge word</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Code>refreshOnVerify</Code>
                  </Td>
                  <Td>boolean</Td>
                  <Td>No</Td>
                  <Td>false</Td>
                  <Td>Auto-refresh after verification</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Code>onVerifyCaptcha</Code>
                  </Td>
                  <Td>function</Td>
                  <Td>Yes</Td>
                  <Td>-</Td>
                  <Td>Callback for verification result</Td>
                </Tr>
                <Tr>
                  <Td>
                    <Code>onError</Code>
                  </Td>
                  <Td>function</Td>
                  <Td>No</Td>
                  <Td>-</Td>
                  <Td>Error handling callback</Td>
                </Tr>
              </Tbody>
            </Table>
          </TabPanel>

          {/* Examples Section */}
          <TabPanel>
            <VStack align="start" spacing={6}>
              <Box width="100%" textAlign="left">
                <Heading size="sm" mb={2}>
                  Basic Usage:
                </Heading>
                <Highlighter content={basicUsageCode} />
              </Box>
              <Box width="100%" textAlign="left">
                <Heading size="sm" mb={2}>
                  Advanced Usage:
                </Heading>
                <Highlighter content={advancedUsageCode} />
              </Box>
            </VStack>
          </TabPanel>

          {/* Question Types Section */}
          <TabPanel>
            <VStack align="start" spacing={4}>
              <Box
                width="100%"
                borderWidth={1}
                borderColor={borderColor}
                p={4}
                rounded="md"
              >
                <Heading size="sm" mb={2}>
                  CHARACTERS
                </Heading>
                <Text mb={2}>Letter-to-number mapping challenge</Text>
                <Highlighter content="Question: D A D D Y Answer: 4 1 4 4 25" />
              </Box>

              <Box
                width="100%"
                borderWidth={1}
                borderColor={borderColor}
                p={4}
                rounded="md"
              >
                <Heading size="sm" mb={2}>
                  NUMBERS
                </Heading>
                <Text mb={2}>Number-to-letter mapping challenge</Text>
                <Highlighter content="Question: 4 1 4 4 25 Answer: D A D D Y" />
              </Box>

              <Box
                width="100%"
                borderWidth={1}
                borderColor={borderColor}
                p={4}
                rounded="md"
              >
                <Heading size="sm" mb={2}>
                  COMPLEX
                </Heading>
                <Text mb={2}>Mixed format challenge</Text>
                <Highlighter content="Question: D - 1 - D - 4 - Y Answer: 4 A 4 D 25" />
              </Box>

              <Box
                width="100%"
                borderWidth={1}
                borderColor={borderColor}
                p={4}
                rounded="md"
              >
                <Heading size="sm" mb={2}>
                  RANDOM
                </Heading>
                <Text>Dynamically generates a mix of the above formats</Text>
              </Box>
            </VStack>
          </TabPanel>

          {/* Error Handling Section */}
          <TabPanel>
            <VStack align="start" spacing={4}>
              <Box width="100%" textAlign="left">
                <Text fontSize="lg">
                  Implement error handling to manage various scenarios:
                </Text>
                <Highlighter content={errorHandlingCode} />
              </Box>
              <Box
                width="100%"
                borderWidth={1}
                borderColor={borderColor}
                p={4}
                rounded="md"
                textAlign="left"
              >
                <Heading size="sm" mb={2}>
                  Common Errors:
                </Heading>
                <UnorderedList spacing={2}>
                  <ListItem>Invalid API key</ListItem>
                  <ListItem>Network connectivity issues</ListItem>
                  <ListItem>Rate limiting</ListItem>
                  <ListItem>Invalid response format</ListItem>
                </UnorderedList>
              </Box>
            </VStack>
          </TabPanel>

          {/* API Response Section */}
          <TabPanel>
            <VStack align="start" spacing={4}>
              <Box width="100%" textAlign="left">
                <Text fontSize="lg">Expected response format:</Text>
                <Highlighter content={`
                   {
                    "status": 200,
                    "result": true
                  }
                `} />
              </Box>
              <Box
                width="100%"
                borderWidth={1}
                borderColor={borderColor}
                p={4}
                rounded="md"
                textAlign="left"
              >
                <Heading size="sm" mb={2}>
                  Response Fields:
                </Heading>
                <UnorderedList spacing={2}>
                  <ListItem>
                    <Code>status</Code>: HTTP status code
                  </ListItem>
                  <ListItem>
                    <Code>result</Code>: Boolean indicating success
                  </ListItem>
                  <ListItem>
                    <Code>message</Code>: Human-readable status message
                  </ListItem>
                  <ListItem>
                    <Code>data</Code>: Additional verification details
                  </ListItem>
                </UnorderedList>
              </Box>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
