import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Heading,
    VStack,
    Container,
    Text,
    Icon,
    useColorModeValue,
  } from "@chakra-ui/react";
  import { FaQuestionCircle } from "react-icons/fa";
  import { MotionElement } from "../../components/MotionElement";
  
  export const FAQs = () => {
    const bgColor = useColorModeValue("white", "gray.800");
    const borderColor = useColorModeValue("gray.200", "gray.700");
    const headingColor = useColorModeValue("gray.800", "white");
    const questionColor = useColorModeValue("blue.600", "blue.300");
  
    const faqs = [
      {
        question: "What is TheLastSpellingBee?",
        answer: "TheLastSpellingBee is a web-based cognitive enhancement platform that combines spelling challenges with numerical patterns. It's designed to boost memory retention and mental agility through engaging, scientifically-designed puzzles that improve both spelling accuracy and pattern recognition abilities."
      },
      {
        question: "How does it enhance cognitive abilities?",
        answer: "Our platform uses proven cognitive science principles to enhance memory and learning through: adaptive difficulty levels, pattern recognition exercises, interactive gameplay mechanics, and real-time performance tracking. The combination of spelling and numerical patterns creates a unique cognitive workout."
      },
      {
        question: "What are the key benefits?",
        answer: "Users experience multiple benefits including: improved memory retention and recall speed, enhanced pattern recognition abilities, better cognitive processing capabilities, increased focus and concentration, strengthened problem-solving skills, and better mental agility and flexibility."
      },
      {
        question: "How is my Memory IQ calculated?",
        answer: "Your Memory IQ score is calculated using advanced algorithms that consider multiple factors: accuracy in spelling, pattern recognition speed, response time, and consistency in performance. This provides a comprehensive measure of your cognitive abilities. Read more in our about us page."
      },
      {
        question: "What makes TheLastSpellingBee unique?",
        answer: "Our platform stands out through its innovative combination of spelling challenges and numerical patterns, scientifically validated approach to cognitive enhancement, adaptive learning system, and comprehensive performance tracking with detailed analytics."
      },
      {
        question: "What is TheLastSpellingBee Re-CAPTCHA?",
        answer: "It's our innovative security solution that combines cognitive challenges with spam prevention. Unlike traditional CAPTCHAs, our system uses spelling and pattern recognition tasks that are engaging for users while effectively blocking automated bots."
      },
      {
        question: "How can I integrate the Re-CAPTCHA API?",
        answer: "Integration is straightforward with our comprehensive documentation, NPM package, and developer support. The API can be easily added to any website using our React component or REST API endpoints."
      },
      {
        question: "Is it suitable for all skill levels?",
        answer: "Yes! Our adaptive learning system automatically adjusts difficulty levels based on user performance, making it suitable for beginners to advanced users. The platform grows with you as your skills improve."
      }
    ];
  
    return (
      <Box py={16} bg="#f5f5f9">
        <Container maxW="4xl">
          <MotionElement type="fadeInY">
            <VStack spacing={8}>
              <Box textAlign="center" mb={8}>
                <Icon as={FaQuestionCircle} w={12} h={12} color="blue.500" mb={4} />
                <Heading
                  as="h2"
                  size="xl"
                  mb={4}
                  color={headingColor}
                  bgGradient="linear(to-r, blue.400, purple.500)"
                  bgClip="text"
                >
          Frequently Asked Questions
        </Heading>
                <Text fontSize="lg" color="gray.600">
                  Find answers to common questions about TheLastSpellingBee
                </Text>
                </Box>
  
              <Accordion allowToggle width="100%">
                {faqs.map((faq, index) => (
                  <AccordionItem
                    key={index}
                    border="1px"
                    borderColor={borderColor}
                    bg={bgColor}
                    rounded="md"
                    mb={4}
                    overflow="hidden"
                  >
                    <AccordionButton 
                      py={4} 
                      _hover={{ bg: 'gray.50' }}
                    >
                <Box flex="1" textAlign="left">
                        <Text 
                          fontWeight="600" 
                          color={questionColor}
                          fontSize="lg"
                        >
                          {faq.question}
                        </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
                    <AccordionPanel 
                      pb={4} 
                      px={6}
                      fontSize="md"
                      lineHeight="tall"
                    >
                      {faq.answer}
            </AccordionPanel>
          </AccordionItem>
                ))}
        </Accordion>
            </VStack>
          </MotionElement>
        </Container>
        </Box>
    );
  };
  