import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
} from "@chakra-ui/react";
import { CertificateView } from "./CertificateView";
import { Score } from "../../../../types/score-types";

export type PlayerResultProps = {
  name: string;
  value: number;
  correct: number;
  incorrect: number;
  score: Score;
};

type PlayerDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  player: PlayerResultProps | undefined;
};

export const PlayerDetailsModal: React.FC<PlayerDetailsModalProps> = ({
  isOpen,
  onClose,
  player,
}) => {
  const totalQuestions = player?.score?.game?.numberOfQuestions ?? 10;
  const score = player?.score?.score ?? 0;
  const correctAnswers = Math.floor(score / 5);
  const incorrectAnswers = totalQuestions - correctAnswers;

  const data = [
    { name: "Correct", value: correctAnswers },
    { name: "Incorrect", value: incorrectAnswers },
  ];
  const COLORS = ["#2f855a", "#c53030"];

  if (!player?.score) {
    <Spinner />;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={"#2b6cb0"}>{player?.name}'s Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
         <CertificateView
            score={player?.score as Score}
            correctAnswers={correctAnswers}
            totalQuestions={totalQuestions}
            data={data}
            COLORS={COLORS}
          /> 
          
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};
