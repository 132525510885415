import {
  Center,
  VStack,
  Heading,
  Text,
  Progress,
} from "@chakra-ui/react";
import { Background } from "../../../common/background/Background";
import { AnimatedBackground } from "./result-chart/AnimatedBackground";
import ContentCard from "../../nav/ContentCard";

export const LoadingScoreCalculation = () => {
  return (
    <ContentCard
      position="relative"
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      width={"100%"}
      borderRadius={0}
      bg={"transparent"}
      p={0}
    >
      <Background isDynamic={true} />
      <AnimatedBackground />
      <Center height="100vh" position="relative" zIndex={2}>
        <VStack 
          spacing={6} 
          bg="white" 
          p={8} 
          borderRadius="xl" 
          boxShadow="xl"
          maxW="600px"
          w="90%"
        >
          <Heading size="lg" textAlign="center" color="blue.600">
            Calculating Your Memory IQ Score
          </Heading>
          <Text fontSize="md" color="gray.600" textAlign="center">
            Please wait while our AI system analyzes your performance and calculates your Memory IQ score...
          </Text>
          <Progress 
            size="xs" 
            isIndeterminate 
            width="100%" 
            colorScheme="blue" 
          />
          <VStack spacing={3} mt={4}>
            <Text fontSize="sm" color="gray.500">
              We are:
            </Text>
            <Text fontSize="sm" color="gray.600">
              ✓ Analyzing response patterns
            </Text>
            <Text fontSize="sm" color="gray.600">
              ✓ Calculating accuracy scores
            </Text>
            <Text fontSize="sm" color="gray.600">
              ✓ Generating personalized feedback
            </Text>
          </VStack>
        </VStack>
      </Center>
    </ContentCard>
  );
}; 