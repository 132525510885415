import { FC } from "react";
import { DisplayLatestResults } from "./DisplayLatestResults";
import { Box } from "@chakra-ui/react";
import { Background } from "../../../common/background/Background";

export const LatestIQResults: FC = () => {
 
  return (
    <Box>
      <Background />
      <DisplayLatestResults paginatePerPage={20} />
    </Box>
  );
};
