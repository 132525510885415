import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputProps,
  Textarea,
  TextareaProps,
} from "@chakra-ui/react";
import { UseFormRegister, FieldError } from "react-hook-form";

interface FormInputProps extends Omit<InputProps, 'type'> {
  label: string;
  name: string;
  error?: FieldError;
  register: UseFormRegister<any>;
  textarea?: boolean;
  rows?: number;
  type?: string;
  textareaProps?: TextareaProps;
}

export const FormInput = ({
  label,
  name,
  error,
  register,
  textarea,
  rows,
  type = "text",
  textareaProps,
  ...rest
}: FormInputProps) => {
  const registerProps = register(name);
  
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      {textarea ? (
        <Textarea
          {...registerProps}
          id={name}
          rows={rows}
          placeholder={label}
          {...textareaProps}
        />
      ) : (
        <Input
          {...registerProps}
          id={name}
          type={type}
          placeholder={label}
          {...rest}
        />
      )}
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}; 