import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    UnorderedList,
    ListItem,
    Grid,
    GridItem,
    Icon,
    Flex,
    Divider,
  } from "@chakra-ui/react";
import { MotionElement } from "../../../components/MotionElement";
import { FaBrain, FaChartLine, FaClock, FaLightbulb } from "react-icons/fa";

type FormulaCardProps = {
  title: string;
  formula: string;
  description: string;
  icon: React.ElementType;
  variables: { label: string; description: string }[];
}

const FormulaCard = ({ title, formula, description, icon, variables }: FormulaCardProps) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Box
      bg={bgColor}
      p={6}
      borderRadius="xl"
      shadow="xl"
      borderWidth={1}
      borderColor={borderColor}
      transition="transform 0.2s"
      _hover={{ transform: "translateY(-4px)" }}
      textAlign={"left"}
    >
      <Flex align="center" mb={4}>
        <Icon as={icon} w={6} h={6} color="blue.500" mr={3} />
        <Heading as="h3" size="md" color={useColorModeValue("gray.800", "white")}>
          {title}
        </Heading>
      </Flex>
      <Text color={useColorModeValue("gray.600", "gray.300")} mb={4}>
        {description}
      </Text>
      <Box
        bg={useColorModeValue("blue.50", "blue.900")}
        p={4}
        borderRadius="md"
        mb={4}
      >
        <Text
          fontFamily="mono"
          fontSize="lg"
          fontWeight="bold"
          textAlign="center"
          color={useColorModeValue("blue.700", "blue.200")}
        >
          {formula}
        </Text>
      </Box>
      <Divider my={4} />
      <UnorderedList spacing={2} textAlign={"left"}>
        {variables.map((variable, index) => (
          <ListItem key={index}>
            <Text as="span" fontWeight="bold">{variable.label}:</Text>{" "}
            {variable.description}
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
};

export const ScientificValidation = () => {
  const headingColor = useColorModeValue("gray.800", "white");

  const formulas = [
    {
      title: "Memory Retention Rate",
      formula: "R = e^(-t/S)",
      description: "Ebbinghaus' Forgetting Curve measures how well information is retained over time",
      icon: FaBrain,
      variables: [
        { label: "R", description: "Retention percentage" },
        { label: "t", description: "Time elapsed since learning" },
        { label: "S", description: "Memory strength (improves with practice)" }
      ]
    },
    {
      title: "Cognitive Load Index",
      formula: "CL = IL + EL - GL",
      description: "Measures the mental effort required during learning and problem-solving",
      icon: FaLightbulb,
      variables: [
        { label: "IL", description: "Intrinsic Load (task complexity)" },
        { label: "EL", description: "Extraneous Load (distractions)" },
        { label: "GL", description: "Germane Load (learning capacity)" }
      ]
    },
    {
      title: "Working Memory Score",
      formula: "PS = (CR - E) / TA × 100",
      description: "N-Back Task measurement for working memory improvement",
      icon: FaChartLine,
      variables: [
        { label: "PS", description: "Performance Score" },
        { label: "CR", description: "Correct Responses" },
        { label: "E", description: "Errors" },
        { label: "TA", description: "Total Attempts" }
      ]
    },
    {
      title: "Reaction Time Improvement",
      formula: "RTI = (BRT - PRT) / BRT × 100",
      description: "Neuroplasticity & Reaction Time Measurement. Measures improvement in cognitive processing speed",
      icon: FaClock,
      variables: [
        { label: "RTI", description: "Reaction Time Improvement %" },
        { label: "BRT", description: "Baseline Reaction Time" },
        { label: "PRT", description: "Post-Practice Reaction Time" },
        { label: "", description: "Users who regularly train with missing letter/number puzzles should show faster recall." },
        { label: "", description: "The shorter reaction time for identifying missing values means improved neural processing speed." }
      ]
    }
  ];

  return (
    <Box py={16}>
      <Container maxW="7xl">
        <MotionElement type="fadeInY">
          <VStack spacing={12}>
            <Box textAlign="center" maxW="3xl" mx="auto">
              <Heading
                as="h1"
                size="2xl"
                mb={6}
                color={headingColor}
                bgGradient="linear(to-r, blue.400, purple.500)"
                bgClip="text"
              >
                Scientific Validation
              </Heading>
              <Text fontSize="xl" textAlign={"left"} color={useColorModeValue("gray.600", "gray.300")}>
                TheLastSpellingBee's effectiveness is backed by cognitive science and
                psychological research. Our platform uses established formulas to
                measure and improve memory, learning, and cognitive performance.
              </Text>
            </Box>

            <Grid
              templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
              gap={8}
              w="full"
            >
              {formulas.map((formula, index) => (
                <GridItem key={index}>
                  <FormulaCard {...formula} />
                </GridItem>
              ))}
            </Grid>
            <Text color={useColorModeValue("gray.600", "gray.300")} fontSize="lg" textAlign="center" mt={6}>
              These scientific principles ensure that <Text as="span" fontWeight="bold" mr={2}>TheLastSpellingBee</Text>
              actively contributes to memory improvement, cognitive efficiency,
              and learning retention over time.
            </Text>
          </VStack>
        </MotionElement>
      </Container>
    </Box>
  );
};
  