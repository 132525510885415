export const currencyFormatter = (value: number) => {
  if (!Number.isFinite(value)) {
    return "-";
  }
  return new Intl.NumberFormat("en-CA", {
    currency: "CAD",
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export const currencyIntFormatter = (value: number) => {
  if (!Number.isFinite(value)) {
    return "-";
  }
  return new Intl.NumberFormat("en-CA", {
    currency: "CAD",
    style: "currency",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export const currencyIntFormatterNoSymbol = (value: number) => {
  if (!Number.isFinite(value)) {
    return "-";
  }
  return new Intl.NumberFormat("en-CA", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export const formatNumberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};