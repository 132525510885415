import { FC, useState } from "react";
import {
  Box,
  Flex,
  Icon,
  Text,
} from "@chakra-ui/react";
import {
  FiHome,
  FiSettings,
  FiRadio,
  FiFile,
  FiFileText,
  FiCreditCard,
} from "react-icons/fi";
import { Link as RouterLink, useLocation } from "react-router-dom";
type SidebarProps = {
  isOpen: boolean;
  onClose: () => void;
}


export const Sidebar: FC<SidebarProps> = ({ isOpen, onClose }) => {
  const [navSize] = useState("large");
  const location = useLocation();

  const navItems = [
    { icon: FiHome, title: "Dashboard", to: "/auth" },
    { icon: FiFileText, title: "My IQ Results", to: "/auth/my-iq-results" },
    { icon: FiFile, title: "Latest IQ Results", to: "/auth/latest-iq-results" },
    { icon: FiCreditCard, title: "Buy Credits", to: "/auth/credit" },
    { icon: FiRadio, title: "Api Key", to: "/auth/api-key" },
    { icon: FiSettings, title: "Settings", to: "/auth/settings" },
  ];
  const isDisplay = isOpen ? "block" : "none";

  const handleNavItemClick = () => {
    if (navSize === "small") {
      onClose();
    }
  };

  return (
    <Box
      w={{ base: "full", md: "64" }}
      position="fixed"
      left={0}
      top={0}
      zIndex={999}
      display={{ base: isDisplay, md: isDisplay, lg: isDisplay, xl: "block", "2xl":"block"  }}
    >
      <Flex
        pos="sticky"
        left="0"
        h="100vh"
        bg={"#FFF"}
        pt={5}
        pl={3}
        marginTop="2.5vh"
        boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
        borderRadius={navSize === "small" ? "5px" : "5px"}
        w={navSize === "small" ? "75px" : "230px"}
        flexDir="column"
        justifyContent="space-between"
      >
        <Flex
          p="5%"
          flexDir="column"
          w="100%"
          alignItems={navSize === "small" ? "center" : "flex-start"}
          as="nav"
        >
          
          {navItems.map((item) => (
            <NavItem
              key={item.to}
              icon={item.icon}
              title={item.title}
              to={item.to}
              isActive={location.pathname === item.to}
              onClick={handleNavItemClick}
            />
          ))}
        </Flex>

        <Flex
          p="5%"
          flexDir="column"
          w="100%"
          alignItems={navSize === "small" ? "center" : "flex-start"}
          mb={4}
        >
          
        </Flex>
      </Flex>
    </Box>
  );
};

type NavItemProps = {
  icon: any;
  title: string;
  to: string;
  isActive: boolean;
  onClick: () => void;
}

export const NavItem: FC<NavItemProps> = ({ icon, title, to, isActive, onClick }) => {
  return (
    <Flex mt={7} flexDir="column" w="100%" alignItems="flex-start">
      <RouterLink to={to} onClick={onClick}>
        <Flex alignItems="center">
          <Icon
            as={icon}
            fontSize="xl"
            color={isActive ? "#2b6cb0" : "gray.500"}
            fontWeight={isActive ? "bold" : ""}
          />
          <Text
            ml={5}
            color={isActive ? "#2b6cb0" : ""}
            fontWeight={isActive ? "bold" : ""}
          >
            {title}
          </Text>
        </Flex>
      </RouterLink>
    </Flex>
  );
};
