import {
  Box,
  Flex,
  Text,
  IconButton,
  Stack,
  Collapse,
  useColorModeValue,
  useDisclosure,
  ChakraProvider,
  HStack,
} from "@chakra-ui/react";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { Sidebar } from "./Sidebar";
import { BrandLogo } from "../../components/BrandLogo";
import { useUser } from "../../hooks/use-user";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useGame } from "../../hooks/use-game";
import { UserMenu } from "./UserMenu";

export const DefaultNav = (props: { [x: string]: any }) => {
  const { user } = useUser();
  const { game } = useGame();
  const { isOpen, onToggle } = useDisclosure();
  const location = useLocation();
  
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  

  return (
    <ChakraProvider>
      <Box>
        <Flex
          bg={bgColor}
          borderBottom="1px"
          borderColor={borderColor}
          position="fixed"
          w="100%"
          zIndex={1000}
          h="60px"
          align="center"
          px={4}
          shadow="sm"
        >
          {/* Mobile Menu Button */}
          <IconButton
            display={{ base: "flex", xl: "none" }}
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant="ghost"
            aria-label="Toggle Navigation"
            mr={2}
          />

          {/* Logo */}
          <RouterLink to={user ? "/auth/" : "/"}>
            <BrandLogo />
          </RouterLink>

          {/* Navigation Items */}
          {!user && !game && (
            <HStack
              spacing={8}
              flex={1}
              justify="center"
              display={{ base: "none", lg: "flex" }}
            >
              {NAV_ITEMS.filter(item => !item.isValidate).map((navItem) => (
                <RouterLink key={navItem.label} to={navItem.href || "#"}>
                  <Text
                    fontSize="sm"
                    color={location.pathname === navItem.href ? "blue.500" : "gray.600"}
                    fontWeight={500}
                    _hover={{ color: "blue.500" }}
                  >
                    {navItem.label}
                  </Text>
                </RouterLink>
              ))}
            </HStack>
          )}

          {/* User Menu */}
          <Box ml="auto">
            {user ? (
            <UserMenu user={user}  />
            ) : (
              !game && (
                <HStack spacing={4} display={{ base: "none", md: "flex" }}>
                  <RouterLink to="/auth/login">
                    <Text 
                    fontSize="sm"
                    fontWeight={500}
                    color="gray.600"
                    _hover={{ color: "blue.500" }}
                  >
                    Sign In
                  </Text>
                </RouterLink>
                <RouterLink to="/auth/register">
                  <Box
                    as="button"
                    px={4}
                    py={2}
                    rounded="md"
                    bg="blue.500"
                    color="white"
                    fontSize="sm"
                    fontWeight={500}
                    _hover={{ bg: "blue.600" }}
                    transition="all 0.2s"
                  >
                    Sign Up
                  </Box>
                </RouterLink>
              </HStack>
              )
            )}
          </Box>
        </Flex>

        {/* Mobile Navigation */}
        <Collapse in={isOpen} animateOpacity>
          <Box
            bg={bgColor}
            p={4}
            display={{ base: "block", lg: "none" }}
            shadow="md"
            position="fixed"
            width="100%"
            top="60px"
            zIndex={999}
          >
            <Stack spacing={4}>
              {NAV_ITEMS.filter(item => !item.isValidate).map((item) => (
                <RouterLink key={item.label} to={item.href || "#"}>
                  <Text
                    py={2}
                    color={location.pathname === item.href ? "blue.500" : "gray.600"}
                    fontWeight={500}
                    _hover={{ color: "blue.500" }}
                  >
                    {item.label}
                  </Text>
                </RouterLink>
              ))}
            </Stack>
          </Box>
        </Collapse>
      </Box>

      {/* Sidebar */}
      {user && !game && <Sidebar isOpen={isOpen} onClose={onToggle} />}
    </ChakraProvider>
  );
};

type NavItem = {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  isValidate?: boolean;
  display?: any;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "About",
    href: "/about-the-last-spelling-bee",
  },
  {
    label: "Re-captcha",
    href: "/api-reference",
  },
  {
    label: "Latest IQ Results",
    href: "/latest-iq-results",
    isValidate: false,
    display: {
      base: "none",
      md: "none",
      lg: "none",
      xl: "block",
      "2xl": "block",
    },
  },
  {
    label: "Check Your IQ Score",
    href: "/my-iq-score",
    isValidate: false,
    display: {
      base: "none",
      md: "none",
      lg: "none",
      xl: "block",
      "2xl": "block",
    },
  },
  {
    label: "Sign In",
    href: "/auth/login",
    isValidate: true,
  },
  {
    label: "Sign Up",
    href: "/auth/register",
    isValidate: true,
  },
];
