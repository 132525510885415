import React, { FC } from "react";
import {
  useDisclosure,
  Button,
  Text,
  useToast,
  Stack,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  MenuItem,
} from "@chakra-ui/react";
import { FaTrash, FaExclamationTriangle } from "react-icons/fa";
import { Game } from "../../types/game-type";
import { useGames } from "../../context/GamesContext";
import { useApiService } from "../../redux-action/api.service";

type DeleteGameProps = {
  game: Game;
};

export const DeleteGame: FC<DeleteGameProps> = ({ game }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);
  const toast = useToast();
  const { refreshGames } = useGames();
  const api = useApiService();

  const onSubmitHandler = async () => {
    try {
      await api.service("games").delete(game?.id?.toString() ?? "");

      toast({
        title: "Game Deleted",
        description: "Game deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });

      onClose();
      await refreshGames();
    } catch (error: any) {
      toast({
        title: "Error deleting game",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <>
      <MenuItem _hover={{ bg: "red.50" }} cursor="pointer" onClick={onOpen}>
        <Icon as={FaTrash} fontSize="sm" />
        <Text ml={2}>Delete</Text>
      </MenuItem>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              display="flex"
              alignItems="center"
            >
              <Icon as={FaExclamationTriangle} color="red.500" mr={2} />
              Delete Game
            </AlertDialogHeader>

            <AlertDialogBody>
              <Stack spacing={4}>
                <Text>
                  Are you sure you want to delete{" "}
                  <Text as="span" fontWeight="bold" color="red.500">
                    {game.name}
                  </Text>
                  ?
                </Text>
                <Text fontSize="sm" color="gray.500">
                  This action cannot be undone. All associated data, including
                  scores and results, will be permanently removed.
                </Text>
              </Stack>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                size="sm"
                variant="ghost"
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={onSubmitHandler}
                ml={3}
                size="sm"
                leftIcon={<FaTrash />}
              >
                Delete Game
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
