import * as yup from 'yup';
import { QuestionType } from '../../types/questions.type';

export const GameSchema = yup.object().shape({
  name: yup.string().required('Game name is required'),
  gameCategory: yup.string().required('Game category is required'),
  questionType: yup.string().oneOf(QuestionType).required('Question type is required'),
  wordLength: yup.number()
    .min(1, 'Word length must be at least 1')
    .max(10, 'Word length cannot exceed 10')
    .required('Word length is required'),
  numberOfQuestions: yup.number()
    .min(1, 'Must have at least 1 question')
    .max(100, 'Cannot exceed 100 questions')
    .default(3)
    .required('Number of questions is required'),
  gameTime: yup.number()
    .min(2, 'Game time must be at least 2 minutes')
    .max(60, 'Game time cannot exceed 60 minutes')
    .default(2)
    .required('Game time is required'),
  expiryDate: yup.string()
    .required('Expiry date is required')
    .test('is-future', 'Expiry date must be in the future', (value) => {
      if (!value) return false;
      const date = new Date(value);
      return date > new Date();
    }),
  expectedUsers: yup
    .number()
    .required('Expected users is required')
    .min(1, 'At least 1 user is required')
    .integer('Must be a whole number'),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type GameSchema = yup.InferType<typeof GameSchema>;
  