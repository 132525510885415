import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Divider,
  UnorderedList,
  ListItem,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { MotionElement } from "../../components/MotionElement";

export const TermsOfService = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const headingColor = useColorModeValue("gray.800", "white");

  return (
    <Box bg={bgColor} py={20}>
      <Container maxW="4xl" bg={"white"} borderRadius={"lg"} p={8} boxShadow={"lg"}>
        <MotionElement type="fadeInY">
          <VStack spacing={8} align="stretch">
            <Heading as="h1" size="xl" textAlign="center" color={headingColor} mb={8}>
              Terms of Service
            </Heading>

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                1. Introduction
              </Heading>
              <Text color={textColor} mb={4}>
                These Terms govern your use of <Text as="span" fontWeight="bold">TheLastSpellingBee</Text>, 
                a web-based platform offering Memory IQ tests designed to enhance cognitive skills. 
                By creating an account or engaging with our services, you acknowledge that you have 
                read, understood, and agree to these Terms.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                2. Services and Pricing
              </Heading>
              <Text color={textColor}  mb={2}>Free Demo Test</Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>
                  Basic Memory IQ test with limited question types
                </ListItem>
                <ListItem>
                  Advanced features and question types are exclusive to paid users
                </ListItem>
                <ListItem>
                  Limited performance analytics and basic results only
                </ListItem>
              </UnorderedList>

              <Text color={textColor} fontWeight="bold" mb={2}>Paid Services</Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>One Test: $1.44 per attempt</ListItem>
                <ListItem>
                  Membership Plan: $29.99 per month, which includes:
                  <UnorderedList spacing={1} mt={2}>
                    <ListItem>Unlimited test attempts</ListItem>
                    <ListItem>Advanced question types and features</ListItem>
                    <ListItem>Detailed performance analytics</ListItem>
                    <ListItem>Printable certificates</ListItem>
                  </UnorderedList>
                </ListItem>
              </UnorderedList>

              <Text color={textColor} fontWeight="bold" mb={2}>Credits and Payments</Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>
                  Credits can be purchased for accessing premium tests and features
                </ListItem>
                <ListItem>
                  All credit purchases are final and non-refundable
                </ListItem>
                <ListItem>
                  Unused credits cannot be converted to cash or transferred
                </ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                3. Account and User Responsibilities
              </Heading>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>
                  Users must be at least the age of majority in their jurisdiction
                </ListItem>
                <ListItem>
                  Accurate and up-to-date registration information is required
                </ListItem>
                <ListItem>
                  Fraudulent activity will result in account termination
                </ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                4. Test Validity and Disclaimer
              </Heading>
              <Text color={textColor} mb={4}>
              The Memory IQ tests provided on <Text as="span" fontWeight="bold">TheLastSpellingBee</Text> are designed for entertainment and cognitive stimulation. The results are not medical or psychological assessments, diagnostic tools for mental conditions, or intended for professional use.
              </Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>Do not constitute medical or psychological assessments.</ListItem>
                <ListItem>Should not be interpreted as diagnostic tools for mental conditions.</ListItem>
                <ListItem>Are not guaranteed to be scientifically validated measures of intelligence.</ListItem>
              </UnorderedList>
              <Text color={textColor} mb={4}>
              If you have concerns about your cognitive abilities, consult a licensed professional.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                5. Usage Restrictions
              </Heading>
              <Text color={textColor} mb={4}>
                By using TheLastSpellingBee, you agree to the following restrictions:
              </Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>Do not use the platform for illegal or unauthorized purposes.</ListItem>
                <ListItem>Do not share or distribute test content without explicit permission.</ListItem>
                <ListItem>Do not attempt to manipulate or interfere with the scoring system.</ListItem>
                <ListItem>Do not upload or transmit malicious code, viruses, or harmful software.</ListItem>
                <ListItem>Do not copy, sell, or exploit any part of the service without our written consent.</ListItem>
              </UnorderedList>
            </Box>

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                6. Payment, Cancellation, and Refund Policy
              </Heading>
              <Text color={textColor} mb={4}>
                All payments are processed securely via third-party payment providers.
              </Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>Memberships are billed on a recurring basis and can be canceled at any time</ListItem>
                <ListItem>Users who cancel a membership will continue to have access until the end of the billing cycle</ListItem>
                <ListItem>Refunds will be granted only in cases of billing errors or service disruptions caused by TheLastSpellingBee</ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                7. API Access for Authenticated Users
              </Heading>
              <Text color={textColor} mb={4}>
                Authenticated users can request an API key to integrate reCAPTCHA and other functionalities 
                into their websites. Users of the API:
              </Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>Must comply with all security guidelines and restrictions</ListItem>
                <ListItem>Are prohibited from reselling or misusing the API for fraudulent purposes</ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                8. Modifications to Services and Terms
              </Heading>
              <Text color={textColor} mb={4}>
                We reserve the right to:
              </Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>Modify or discontinue any aspect of the service at our sole discretion</ListItem>
                <ListItem>Change these Terms at any time, with updates posted on this page</ListItem>
              </UnorderedList>
              <Text color={textColor} mb={4}>
                It is your responsibility to review these Terms periodically. Continued use after 
                changes constitutes acceptance of the new Terms.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                9. Limitation of Liability
              </Heading>
              <Text color={textColor} mb={4}>
                <Text as="span" fontWeight="bold">TheLastSpellingBee</Text> is provided "as is" without warranties 
                of any kind. We are not responsible for:
              </Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>Service interruptions or unexpected downtime</ListItem>
                <ListItem>Loss of test data due to technical failures</ListItem>
                <ListItem>User actions that violate these Terms</ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                10. Contact Information
              </Heading>
              <Text color={textColor} mb={4} fontWeight="bold">
                For questions regarding these Terms, please:
              </Text>
              <Link 
                href="/contact-us" 
                color="blue.500" 
                _hover={{ textDecoration: 'underline', color: 'blue.600' }}
              >
                Contact our Support Team
              </Link>
            </Box>

            <Box pt={8}>
              <Text color={textColor} fontSize="sm" fontWeight="bold">
                Last updated: {new Date().toLocaleDateString()}
              </Text>
            </Box>
          </VStack>
        </MotionElement>
      </Container>
    </Box>
  );
};
