import { Box } from '@chakra-ui/react'
import { useMemo, useState, useEffect } from 'react'
import { CloudLayer } from './CloudLayer'

const CLOUD_LAYERS = 3

const gradients = [
	{ start: '#576DEC', end: '#2E92F2' }, // Default blue
	{ start: '#4ade80', end: '#22c55e' }, // Light green
	{ start: '#818cf8', end: '#6366f1' }, // Light purple
	{ start: '#fb923c', end: '#f97316' }, // Light orange
	{ start: '#f472b6', end: '#ec4899' }, // Light pink
	{ start: '#38bdf8', end: '#0ea5e9' }, // Light blue
]

type BackgroundProps = {
	isDynamic?: boolean;
	customGradient?: { start: string; end: string };
}

export const Background = ({ isDynamic = false, customGradient }: BackgroundProps) => {
	const [currentGradient, setCurrentGradient] = useState(gradients[0])

	useEffect(() => {
		if (isDynamic) {
			// Set initial random gradient
			const randomIndex = Math.floor(Math.random() * gradients.length)
			setCurrentGradient(gradients[randomIndex])
		} else if (customGradient) {
			setCurrentGradient(customGradient)
		} else {
			setCurrentGradient(gradients[0]) // Default gradient
		}
	}, [isDynamic, customGradient])

	const cloudLayers = useMemo(
		() =>
			Array(CLOUD_LAYERS)
				.fill(null)
				.map((x, i) => <CloudLayer key={i} />),
		[]
	)

	return (
		<Box position="absolute" inset={0} overflow="hidden" zIndex={-1}>
			<Box 
				position="fixed" 
				inset={0} 
				bgGradient={`linear(to-tr, ${currentGradient.start}, ${currentGradient.end})`} 
				zIndex={-3}
				transition="background 0.5s ease-in-out"
			/>
			{cloudLayers}
			<Box 
				position="absolute" 
				inset={0} 
				bg="rgba(63,129,238,0.6)" 
				zIndex={-1}
			/>
		</Box>
	)
}
