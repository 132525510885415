import { Component, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Home } from "./apps/home";
import { Spell } from "./apps/spelling/Spell";
import { SpellingGamePlay } from "./apps/spelling/game-play/SpellingGamePlay";
import { SpellingResult } from "./apps/spelling/game-play/SpellingResult";
import { Login } from "./apps/auth/Login";
import { SignUp } from "./apps/auth/SignUp";
import { ForgetPassword } from "./apps/auth/ForgetPassword";
import { Dashboard } from "./apps/dashboard";
import { GameResult } from "./apps/user/GameResult";
import { ResultDetails } from "./apps/user/ResultDetails";
import { Settings } from "./apps/user/Settings";
import { AboutRecaptcha } from "./apps/recaptcha/AboutRecaptcha";
import { ApiReference } from "./apps/recaptcha/ApiReference";
import { ApiKey } from "./apps/apikey/ApiKey";
import { LatestIQResults } from "./apps/spelling/game-play/LatestIQResults";
import { ContactUs } from "./apps/home/ContactUs";
import { VerifyEmail } from "./apps/auth/VerifyEmail";
import { ResetPassword } from "./apps/auth/ResetPassword";
import { NotFound } from "./apps/404/NotFound";
import { NPMDocumentation } from "./apps/recaptcha/NPMDocumentation";
import { CheckIQScore } from "./apps/spelling/IQScore/CheckIQScore";
import { useUser } from "./hooks/use-user";
import { useGame } from "./hooks/use-game";
import { MyIQScores } from "./apps/user/MyIQScores";
import { Credit } from "./apps/credit/Credit";
import { PaymentSuccess } from "./apps/credit/PaymentSuccess";
import { PaymentCancel } from "./apps/credit/PaymentCancel";
import { PrivacyPolicy } from "./apps/privacy-and-terms/PrivacyPolicy";
import { TermsOfService } from "./apps/privacy-and-terms/TermsOfService";
import { AboutTheLastSpellingBee } from "./apps/home/about";
import { GetCertificate } from "./apps/spelling/IQScore/GetCertificate";


const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useUser();
  const location = useLocation();
  if (!user) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
};

const ProtectedGameRoute = ({ children }: { children: JSX.Element }) => {
  const { game } = useGame();
  const location = useLocation();

  useEffect(() => {
    if (!game && location.pathname !== '/spelling/result') {
      window.location.href = '/';
    }
  }, [game, location.pathname]);

  return children;
};


class AppRoutes extends Component {
  render() {
    return (
      <Routes>
        {/* Re-Captcha Route */}
        <Route path="/api-reference" element={<ApiReference />} />
        <Route path="/about-recaptcha" element={<AboutRecaptcha />} />
        <Route path="/npm/the-last-spelling-bee-re-captcha" element={<NPMDocumentation />} />

       

        <Route path="/" element={<Home />} />
        <Route path="/about-the-last-spelling-bee" element={<AboutTheLastSpellingBee />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route
          path="/email-password-reset/:email/:token"
          element={<ResetPassword />}
        />
        <Route path="/spell" element={<Spell />} />
        <Route path="/spell/:gameId" element={<Spell />} />
       
       
        <Route path="/latest-iq-results" element={<LatestIQResults />} />
        <Route path="/my-iq-score" element={<CheckIQScore />} />
        <Route path="/get-certificate/:email/:gameScoreId" element={<GetCertificate />} />
        <Route path="/verify-email/:token" element={<VerifyEmail />} />
        <Route path="/contact-us" element={<ContactUs />} />

        <Route path="/auth/login" element={<Login />} />
        <Route path="/auth/register" element={<SignUp />} />
        <Route path="/auth/forget-password" element={<ForgetPassword />} />
        <Route path='/email-password-reset/:email/:token' element={<ResetPassword />} />
        <Route
          path="/auth/*"
          element={
            <ProtectedRoute>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/game-result/:gameId" element={<GameResult />} />
                <Route path="/result-details/:gameId/:category" element={<ResultDetails />} />
                <Route path="/my-iq-results" element={<MyIQScores />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/api-key" element={<ApiKey />} />
                <Route path="/latest-iq-results" element={<LatestIQResults />} /> 
                <Route path="/credit" element={<Credit />} />
                <Route path="/payment/success" element={<PaymentSuccess />} />
                <Route path="/payment/cancel" element={<PaymentCancel />} />
              </Routes>
            </ProtectedRoute>
          }
        />
        <Route
          path="/spelling/*"
          element={
            <ProtectedGameRoute>
              <Routes>
                <Route path="/" element={<SpellingGamePlay />} /> 
                <Route path="/result" element={<SpellingResult />} />
              </Routes>
            </ProtectedGameRoute>
          }
        />
        <Route
          path="/auth/logout"
          element={<Navigate to="/auth/login" replace />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
}

export default AppRoutes;
