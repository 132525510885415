import {
  Box,
  Container,
  Stack,
  Text,
  Link,
  useColorModeValue,
} from '@chakra-ui/react'
import { BrandLogo } from "../../components/BrandLogo";

export const DefaultFooter = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      borderTop={1}
      borderStyle={"solid"}
      borderColor={borderColor}
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
        // mt={209}
      >
        <BrandLogo />
        <Stack direction={'row'} spacing={6}>
          <Link href={"/terms-of-service"} color={linkColor}>
            Terms of Service
          </Link>
          <Link href={"/privacy-policy"} color={linkColor}>
            Privacy Policy
          </Link>
          <Link href={"/contact-us"} color={linkColor}>
            Contact
          </Link>
        </Stack>
        <Text>{`© ${new Date().getFullYear()} TheLastSpellingBee. All rights reserved`}</Text>
      </Container>
    </Box>
  )
}