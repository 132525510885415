import { useAppSelector } from "../redux-action/hooks/useTypedSelector";
import { selectCredits, selectUser} from "../redux-action/slices/auth-slice";
import { User } from "../types/user-types";

export const useUser = () => {
  const user = useAppSelector(selectUser);
  const credits = useAppSelector(selectCredits);
  return {
    user: user?.user as User,
    newCredits: credits > 0 ? credits : user?.credit,
    token: user?.access_token,
  };
};
