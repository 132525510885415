import axios, { AxiosInstance } from 'axios';
import { useUser } from '../hooks/use-user';
import { apiUrl } from '../common/config';

class ApiService {
  private api: AxiosInstance;
  
  constructor(access_token?: string) {
    this.api = axios.create({
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
        ...(access_token ? { Authorization: `Bearer ${access_token}` } : {}),
      },
    });
  }
  service(name: string) {
    return {
      create: async (data: any) => {
        const response = await this.api.post(`/${name}`, data);
        return response.data;
      },
      find: async () => {
        const response = await this.api.get(`/${name}`);
        return response.data;
      },
      patch: async (id: string, data: any) => {
        const response = await this.api.patch(`/${name}/${id}`, data);
        return response.data;
      },
      delete: async (id: string) => {
        const response = await this.api.delete(`/${name}/${id}`);
        return response.data;
      },
      signOut: async () => {
        const response = await this.api.post(`/${name}/logout`);
        return response.data;
      },
    };
  }
}

export const api = new ApiService(); 


export const useApiService = () => {
    const { token } = useUser();
    return new ApiService(token);
};
