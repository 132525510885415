import { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";
import { useGame } from "./use-game";
import { apiUrl } from "../common/config";

export const useSocket = () => {
  const { user, game, currentScore } = useGame();
  const [playersScores, setPlayersScores] = useState<
    Record<string, { name: string; score: number }>
  >({});
  const [socket, setSocket] = useState<Socket | null>(null);
  const gameId = game?.id;

  useEffect(() => {
    if (!gameId) {
      console.error("Game ID is required to connect to the socket.");
      return;
    }

    // Clean up any existing socket
    if (socket) {
      socket.disconnect();
    }

    // Get stored socket ID from sessionStorage instead of localStorage
    const storedSocketId = sessionStorage.getItem(`socket_id_${gameId}`);

    // Initialize socket connection
    const newSocket = io(apiUrl, {
      transports: ['websocket', 'polling'],
      query: { 
        socketId: storedSocketId || undefined,
        gameId 
      },
    });

    newSocket.on("connect", () => {
      // Store socket ID in sessionStorage with game-specific key
      sessionStorage.setItem(`socket_id_${gameId}`, newSocket.id || '');

      // Join game room
      const playerName = user?.firstName || "Unknown Player";
      newSocket.emit("joinGame", {
        name: playerName, 
        gameId, 
        score: currentScore 
      });
    });

    newSocket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    // Listen for real-time updates filtered by gameId
    newSocket.on("updateScores", (scores) => {
      const filteredScores = Object.fromEntries(
        Object.entries(scores).filter(([_, player]: [string, any]) => 
          player.gameId === gameId
        )
      );
      setPlayersScores(filteredScores as Record<string, { name: string; score: number }>);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
      sessionStorage.removeItem(`socket_id_${gameId}`);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId, user]);

  const updateScore = (newScore: number) => {
    if (socket && socket.connected) {
      socket.emit("updateScore", { 
        playerId: socket.id,
        score: newScore
      });
    }
  };

  return { playersScores, updateScore };
};