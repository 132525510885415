import {  
  SimpleGrid, 
  Spinner, 
  Container, 
  Flex,
  Box,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { GameCard } from "../game/GameCard";
import { useEffect } from "react";
import ContentCard from "../nav/ContentCard";
import { useUser } from "../../hooks/use-user";
import { useGames } from "../../context/GamesContext";
import { Background } from "../../common/background/Background";
import { AnimatedTypingText } from "../../components/AnimatedTypingText";
import { CreateGame } from "../game/CreateGame";


export const Dashboard = () => {
  const { user } = useUser();
  const { games, isLoading, fetchGames } = useGames();


  useEffect(() => {
    if (user?.id) {
      fetchGames(user.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" minH="50vh">
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Flex>
    );
  }

  return (
    <ContentCard bg="transparent">
      <Background />
      {/* Header Section */}
      <Box 
        borderRadius="xl" 
        px={8} 
        py={12}
        textAlign="center"
        position="relative"
        overflow="hidden"
        mb={8}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="linear-gradient(135deg, blue.600 0%, purple.500 100%)"
          opacity={0.9}
          zIndex={0}
        />
        <VStack spacing={4} position="relative" zIndex={1}>
          <Heading 
            size="2xl" 
            color="white"
            textShadow="0 2px 4px rgba(0,0,0,0.2)"
          >
            <AnimatedTypingText text="Memory IQ Tests" speed={0.2} />
          </Heading>
          <Text 
            fontSize="xl" 
            color="whiteAlpha.900"
            maxW="2xl"
            textShadow="0 1px 2px rgba(0,0,0,0.1)"
          >
            Create, manage and track memory IQ tests for your team
          </Text>
        </VStack>
      </Box>

      {/* Create Game Button */}
      <Flex justify="flex-end" px={8} mb={6}>
        <CreateGame />
      </Flex>

      {/* Games Grid */}
      <Container maxW="container.xl" py={8}>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
          {games.map((game) => (
            <GameCard key={game.id} game={game} />
          ))}
        </SimpleGrid>
      </Container>
    </ContentCard>
  );
};
