import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Icon,
  Button,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaTimesCircle, FaArrowLeft, FaCreditCard } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ContentCard from "../nav/ContentCard";
import { Background } from "../../common/background/Background";

export const PaymentCancel = () => {
  const navigate = useNavigate();
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <ContentCard bg="transparent">
      <Background />
      <Container maxW="container.md" py={20}>
        <VStack spacing={8} textAlign="center">
          <Box
            bg={bgColor}
            p={8}
            borderRadius="2xl"
            boxShadow="xl"
            border="1px solid"
            borderColor={borderColor}
            width="100%"
            maxW="500px"
          >
            <Icon 
              as={FaTimesCircle} 
              w={16} h={16}
              color="red.500" 
              mb={4}
            />
            <Heading 
              size="2xl" 
              color={useColorModeValue("gray.800", "white")}
              mb={4}
            >
              Payment Cancelled
            </Heading>
            <Text 
              fontSize="xl" 
              color={useColorModeValue("gray.600", "gray.300")}
              mb={8}
            >
              Your payment was cancelled. No charges were made.
            </Text>
            <Flex gap={4} justify="center" wrap="wrap">
              <Button
                size="md"
                colorScheme="blue"
                leftIcon={<FaCreditCard />}
                onClick={() => navigate("/auth/credit")}
              >
                Try Again
              </Button>
              <Button
                size="md"
                variant="outline"
                colorScheme="blue"
                leftIcon={<FaArrowLeft />}
                onClick={() => navigate("/dashboard")}
              >
                Back to Dashboard
              </Button>
            </Flex>
          </Box>
        </VStack>
      </Container>
    </ContentCard>
  );
}; 