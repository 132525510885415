import {
  Flex,
  Box,
  Heading,
  Text,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AnimatedTypingText } from "../../components/AnimatedTypingText";
import { CreateNewApiKey } from "./CreateNewApiKey";
import { useEffect } from "react";
import dateFormat from "dateformat";
import { getExDate } from "../spelling/game-play/helper";
import ContentCard from "../nav/ContentCard";
import { Recaptcha } from "../../types/re-captcha-type";
import { useUser } from "../../hooks/use-user";
import { useReCaptcha } from '../../context/ReCaptchaContext';

export const ApiKey = () => {
  const { user } = useUser();
  const { reCaptchas, isLoading, fetchReCaptchas } = useReCaptcha();

  useEffect(() => {
    if (user?.id) {
      fetchReCaptchas(user.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <ContentCard>
        <Flex justify="center" align="center" h="50vh">
          <Spinner size="xl" color="blue.500" />
        </Flex>
      </ContentCard>
    );
  }


  return (
    <ContentCard>
      <Box borderRadius="15px" px="0px" display="flex">
        <Heading fontSize="32px" mb={5} textAlign={"center"}>
          <AnimatedTypingText text="GET Re-captcha API Key" speed={0.1} />
        </Heading>
      </Box>
      <Flex
        borderRadius={5}
        px="0px"
        direction="column"
        p={5}
        bg={"#FFF"}
        boxShadow={"lg"}
      >
        <Box borderRadius="15px" px="0px" display="flex">
          <Text>
            The following is a list of your confidential API keys. It is
            important to keep in mind that once generated, these keys will not
            be shown again.<br></br>
            <br></br>
            It is imperative that you keep your API key private and do not
            reveal it to others or include it in the browser or client-side
            code.
            <br></br>
            To ensure the security of your account,ThelastSpellingBee may replace
            any API keys that have been discovered to have been made public for
            safety purposes.
          </Text>
        </Box>
        <Box borderRadius="15px" px="0px" display="flex" >
        <Flex justify="flex-end" minW={'100%'} pt={9}>
            <CreateNewApiKey  />
        </Flex>
        </Box>
        <Box borderRadius="15px" px="0px" display="flex" mt={10} minW={'100%'}>
      
          <TableContainer  minW={'100%'}>
            <Table variant="striped" colorScheme="gray" minW={'100%'}>
              <Thead>
                <Tr>
                  <Th>Domain Name</Th>
                  <Th>Key</Th>
                  <Th>Last Use</Th>
                  <Th>Created</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
              {reCaptchas &&
                reCaptchas.map((apiKey: Recaptcha, index) => (
                    <Tr  key={index}>
                    <Td>{apiKey.domainName}</Td>
                    <Td>{apiKey.token}</Td>
                    <Td>
                    {dateFormat(
                  getExDate(apiKey.updatedAt ?? ""),
                  "ddd, mmmm dS, yyyy",
                )}</Td>
                    <Td>
                    {dateFormat(
                  getExDate(apiKey.createdAt ?? ""),
                  "ddd, mmmm dS, yyyy",
                )}</Td>
                    <Td></Td>
                  </Tr>
                ))} 
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Flex>
    </ContentCard>
  );
};