import { Alert, AlertIcon } from "@chakra-ui/react";
import { useSoundPlay } from "./use-sound-play";

export const useAnswerFeedback = () => {
  const { playSound } = useSoundPlay();

  const renderAnswer = (score: number, messageFeedback: string) => {
   
    const getAlertStatus = (isCorrect: any) => {
      const soundKey = isCorrect
        ? `correct/${Math.floor(Math.random() * 5) + 1}`
        : `wrong/${Math.floor(Math.random() * 6) + 1}`;

      playSound(soundKey);

      if (isCorrect) {
        score += 5;
        return "success";
      }
      return "error";
    };

    const alertStatus = getAlertStatus(score);

    return (
      <Alert status={alertStatus} borderRadius={4} mt={20}>
        <AlertIcon />
        {messageFeedback || ""}
      </Alert>
    );
  };

  return { renderAnswer };
};