import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Icon,
  Button,
  Flex,
  useColorModeValue,
  Spinner,
} from "@chakra-ui/react";
import { FaCheckCircle, FaArrowRight, FaCreditCard } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ContentCard from "../nav/ContentCard";
import { Background } from "../../common/background/Background";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux-action/store";
import { updateUserCredits } from "../../redux-action/slices/auth-slice";
import { useApiService } from "../../redux-action/api.service";
import { useToast } from "@chakra-ui/react";

export const PaymentSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const api = useApiService();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const subTextColor = useColorModeValue("gray.600", "gray.300");

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        setIsLoading(true);
        const params = new URLSearchParams(window.location.search);
        const sessionId = params.get('session_id');

        if (sessionId) {
          const response = await api.service(`payments/verify/${sessionId}`).find();
          console.log('response..', response);
          if (response.credits) {
            dispatch(updateUserCredits(response.credits));
            toast({
              title: "Payment Successful",
              description: "Your credits have been added to your account",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          }
        }
      } catch (error: any) {
        if (error?.response?.status === 400) {
          toast({
            title: "Payment Already Processed",
            description: "Your credits have already been added to your account",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Verification Error",
            description: error.message || "Failed to verify payment",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } finally {
        setIsLoading(false);
      }
    };

    verifyPayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentCard bg="transparent">
      <Background />
      <Container maxW="container.md" py={20}>
        <VStack spacing={8} textAlign="center">
          <Box
            bg={bgColor}
            p={8}
            borderRadius="2xl"
            boxShadow="xl"
            border="1px solid"
            borderColor={borderColor}
            position="relative"
            width="100%"
            maxW="500px"
          >
            {isLoading ? (
              <VStack spacing={4}>
                <Spinner size="xl" color="blue.500" thickness="4px" />
                <Text fontSize="lg" color="gray.600">
                  Verifying your payment...
                </Text>
              </VStack>
            ) : (
              <>
                <Icon 
                  as={FaCheckCircle} 
                  w={16} h={16} 
                  color="green.500"
                  mb={4}
                />
                <Heading 
                  size="2xl" 
                  color={textColor}
                  mb={4}
                >
                  Payment Successful!
                </Heading>
                <Text 
                  fontSize="xl" 
                  color={subTextColor}
                  mb={8}
                >
                  Your credits have been added to your account
                </Text>
                <Flex gap={4} justify="center" wrap="wrap">
                  <Button
                    size="md"
                    colorScheme="blue"
                    leftIcon={<FaCreditCard />}
                    onClick={() => navigate("/auth/credit")}
                  >
                    Buy More Credits
                  </Button>
                  <Button
                    size="md"
                    variant="outline"
                    colorScheme="blue"
                    rightIcon={<FaArrowRight />}
                    onClick={() => navigate("/auth")}
                  >
                    Return to Dashboard
                  </Button>
                </Flex>
              </>
            )}
          </Box>
        </VStack>
      </Container>
    </ContentCard>
  );
}; 