import { useDispatch } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { AppDispatch } from '../redux-action/store';
import { authenticate, signUpUser, updateUserCredits, authenticateWithGoogle } from '../redux-action/slices/auth-slice';
import { LoginSchema, RegisterSchema } from '../apps/auth/schema';
import { AccountTypes } from '../types/account-types';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleAuth } from '../types/google-auth.types';

export const useAuth = () => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();

  const handleLogin = async (data: LoginSchema) => {
    try {
      const resultAction = await dispatch(authenticate(data)).unwrap();
      if (resultAction?.user) {
        
        dispatch(updateUserCredits(resultAction.user.credit));
        
        toast({
          title: 'Login successful',
          status: 'success',
          duration: 2000,
        });
        
        window.location.href = '/auth';
      }
    } catch (err) {
      toast({
        title: 'Login failed',
        description: 'Invalid email or password',
        status: 'error',
        duration: 3000,
      });
      console.error('Login failed:', err);
    }
  };

  const handleSignUp = async (formData: RegisterSchema) => {
    try {
      const { confirmPassword, ...signUpData } = formData;
      
      const userData = {
        ...signUpData,
        accountType: 'free' as AccountTypes,
        credit: 1,
        isActive: true,
        provider: 'internal',
      };

      const resultAction = await dispatch(signUpUser(userData)).unwrap();
      
      if (resultAction) {
        toast({
          title: 'Registration successful',
          status: 'success',
          duration: 2000,
        });
        window.location.href = '/auth';
      }
    } catch (err) {
      toast({
        title: 'Registration failed',
        description: 'Please try again',
        status: 'error',
        duration: 2000,
      });
      console.error('Registration failed:', err);
    }
  };

  const handleGoogleSignIn = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const googleUserInfo = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
  
        const googleAuth: GoogleAuth = {
          email: googleUserInfo.data.email,
          firstName: googleUserInfo.data.given_name,
          lastName: googleUserInfo.data.family_name,
        };
        const resultAction = await dispatch(
          authenticateWithGoogle(googleAuth)
        ).unwrap();
        
        if (resultAction) {
          dispatch(updateUserCredits(resultAction.user.credit));
          
          toast({
            title: 'Login successful',
            status: 'success',
            duration: 2000,
          });
          
          window.location.href = '/auth';
        }
      } catch (error) {
        toast({
          title: 'Google sign-in failed',
          description: 'Please try again',
          status: 'error',
          duration: 3000,
        });
        console.error("Google sign-in failed:", error);
      }
    },
  });

  return {
    handleLogin,
    handleSignUp,
    handleGoogleSignIn
  };
}; 