import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  useToast,
  Icon,
  VStack,
  Heading,
  Container,
  RadioGroup,
  Radio,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { Instructions } from "./Instructions";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CustomSelect } from "../../components/CustomSelect";
import ContentCard from "../nav/ContentCard";
import { yupResolver } from "@hookform/resolvers/yup";
import { StartSpellingGameSchema } from "./schema";
import { Background } from "../../common/background/Background";
import { api } from "../../redux-action/api.service";
import { useAppDispatch } from "../../redux-action/hooks/useTypedSelector";
import {
  setGameData,
  setError,
  setLoading,
} from "../../redux-action/slices/game-slice";
import { FaCheckCircle, FaChartLine, FaPlay, FaBrain } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import { MotionElement } from "../../components/MotionElement";
import { AgeGroups } from "../../types/age-group-types";
import { CountrySelect } from "../../components/form/CountrySelect";

export const Spell = () => {
  const { gameId } = useParams();
  const toast = useToast();
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(StartSpellingGameSchema),
  });

  const [isComplete, setIsComplete] = useState(false);

  const checkExpectedUsers = async () => {
    if (gameId) {
      try {
        const isExpectedUsers = await api
          .service(`game-scores/check-expectedUsers/${gameId}`)
          .find();
        setIsComplete(isExpectedUsers);
      } catch (error) {
        console.error("Error checking game completion:", error);
      }
    }
  };

  useEffect(() => {
    checkExpectedUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);

  const onSubmitHandler = async (data: StartSpellingGameSchema) => {
    try {
      dispatch(setLoading(true));

      if (gameId) {
        const response = await api.service("game-scores").create({
          ...data,
          isPublic: data.isPublic,
          gameId: gameId,
        });

        await dispatch(setGameData(response));

        toast({
          title: "Game created.",
          description: "We've created your game for you.",
          status: "success",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });

        setTimeout(() => {
          window.location.href = "/spelling";
        }, 1000);
      } else {
        dispatch(setError("Invite link not found"));
        toast({
          title: "Invite link not found.",
          description: "We've not found your game.",
          status: "error",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      dispatch(setError(error.message));
      console.error(error.message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  if (isComplete) {
    return (
      <ContentCard bg="transparent">
        <Background />
        <Container
          maxW="4xl"
          py={20}
          bg={"white"}
          p={4}
          rounded={"lg"}
          shadow={"md"}
        >
          <MotionElement type="fadeInY">
            <VStack spacing={8} textAlign="center">
              <Icon as={FaCheckCircle} w={20} h={20} color="green.400" mb={4} />
              <Heading
                size="xl"
                bgGradient="linear(to-r, green.400, teal.500)"
                bgClip="text"
              >
                Memory IQ Test Complete!
              </Heading>
              <Text fontSize="xl" color="gray.600">
                The required number of participants have completed this Memory
                IQ test.
              </Text>
              <Box p={6} bg="white" rounded="xl" maxW="2xl" w="full">
                <VStack spacing={4}>
                  <Text fontSize="lg">
                    You can now check your IQ score and see how you compare to
                    others!
                  </Text>
                  <Button
                    as={RouterLink}
                    to="/my-iq-score"
                    size="lg"
                    colorScheme="blue"
                    leftIcon={<FaChartLine />}
                    _hover={{
                      transform: "translateY(-2px)",
                      boxShadow: "lg",
                    }}
                    transition="all 0.2s"
                  >
                    Check Your IQ Score
                  </Button>
                </VStack>
              </Box>
              <Text color="gray.500" fontSize="sm">
                Thank you for participating in our Memory IQ test!
              </Text>
            </VStack>
          </MotionElement>
        </Container>
      </ContentCard>
    );
  }

  return (
    <ContentCard bg={"transparent"}>
      <Background isDynamic={true} />
      <Container maxW="2xl" py={12}>
        <VStack spacing={8} width="100%">
          <VStack spacing={3} textAlign="center" mb={4}>
            <Icon as={FaBrain} w={12} h={12} color="gray.50" />
            <Heading size="lg" color="white">
              Memory IQ Test
            </Heading>
          </VStack>

          <Box
            rounded={"xl"}
            bg={"white"}
            boxShadow={"xl"}
            p={8}
            width="100%"
            borderWidth="1px"
            borderColor="gray.100"
          >
            <Instructions />
            <Divider my={6} />
            <Box as="form" onSubmit={handleSubmit(onSubmitHandler)}>
              <Text
                color="gray.600"
                fontSize="md"
                maxW="xl"
                textAlign="left"
                mb={4}
              >
                Your name will appear on your certificate exactly as you enter
                it. We will send your certificate to your email.
              </Text>
              <VStack spacing={6} align="stretch">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                  <FormControl isRequired>
                    <FormLabel fontWeight="medium">First Name</FormLabel>
                    <Input
                      type="text"
                      {...register("firstName")}
                      placeholder="First Name"
                      required
                      isInvalid={!!errors.firstName}
                      size="lg"
                      _focus={{
                        borderColor: "blue.400",
                        boxShadow: "0 0 0 1px blue.400",
                      }}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel fontWeight="medium">Last Name</FormLabel>
                    <Input
                      type="text"
                      {...register("lastName")}
                      placeholder="Last Name"
                      required
                      isInvalid={!!errors.lastName}
                      size="lg"
                      _focus={{
                        borderColor: "blue.400",
                        boxShadow: "0 0 0 1px blue.400",
                      }}
                    />
                  </FormControl>
                </SimpleGrid>

                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                  <FormControl isRequired>
                    <FormLabel fontWeight="medium">Email</FormLabel>
                    <Input
                      type="email"
                      {...register("email")}
                      placeholder="Your email address"
                      isInvalid={!!errors.email}
                      required
                      size="lg"
                      _focus={{
                        borderColor: "blue.400",
                        boxShadow: "0 0 0 1px blue.400",
                      }}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel fontWeight="medium">Age Group</FormLabel>
                    <CustomSelect
                      register={register}
                      id="ageGroup"
                      placeholder="Select Age Group"
                      defaultValue={""}
                      options={AgeGroups}
                    />
                  </FormControl>
                </SimpleGrid>
                <CountrySelect
                  control={control}
                  name="country"
                  label="Country"
                  error={errors.country?.message}
                  isRequired
                />
                <FormControl isRequired>
                  <FormLabel fontWeight="medium">Score Visibility</FormLabel>
                  <RadioGroup defaultValue="false">
                    <VStack spacing={3} align="start">
                      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                        <Radio
                          {...register("isPublic")}
                          value="true"
                          colorScheme="green"
                          size="lg"
                        >
                          <Text fontSize="md" color="gray.700">
                            Share my score publicly
                          </Text>
                        </Radio>
                        <Radio
                          {...register("isPublic")}
                          value="false"
                          colorScheme="red"
                          size="lg"
                        >
                          <Text fontSize="md" color="gray.700">
                            Keep my score private
                          </Text>
                        </Radio>
                      </SimpleGrid>
                    </VStack>
                  </RadioGroup>
                  <Text fontSize="sm" color="gray.500" mt={2}>
                    Public scores appear in leaderboards
                  </Text>
                </FormControl>
                <Divider my={4} />

                <Button
                  colorScheme="blue"
                  type="submit"
                  size="lg"
                  width="full"
                  leftIcon={<Icon as={FaPlay} />}
                  isLoading={isSubmitting}
                  loadingText="Starting..."
                  py={7}
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "lg",
                  }}
                  transition="all 0.2s"
                >
                  Start Memory IQ Test
                </Button>
              </VStack>
            </Box>
          </Box>
        </VStack>
      </Container>
    </ContentCard>
  );
};
