import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    SimpleGrid,
    Icon,
    Flex,
    List,
    ListItem,
    ListIcon,
  } from "@chakra-ui/react";
import { MotionElement } from "../../../components/MotionElement";
import { 
  FaBrain, 
  FaChartLine, 
  FaGamepad, 
  FaGraduationCap, 
  FaUsers,
  FaCheckCircle 
} from "react-icons/fa";

type FeatureCardProps = {
  title: string;
  description: string;
  icon: React.ElementType;
}

const FeatureCard = ({ title, description, icon }: FeatureCardProps) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.700");

  return (
    <Box
      bg={bgColor}
      p={6}
      borderRadius="xl"
      shadow="xl"
      borderWidth={1}
      borderColor={borderColor}
      transition="transform 0.2s"
      _hover={{ transform: "translateY(-4px)" }}
    >
      <Flex align="center" mb={4}>
        <Icon as={icon} w={6} h={6} color="blue.500" mr={3} />
        <Heading as="h3" size="md" color={useColorModeValue("gray.800", "white")}>
          {title}
        </Heading>
      </Flex>
      <Text color={useColorModeValue("gray.600", "gray.300")} textAlign={"left"}>
        {description}
      </Text>
    </Box>
  );
};

export const AboutTheLastSpellingBeeIQ = () => {
  const textColor = useColorModeValue("gray.600", "gray.300");
  const headingColor = useColorModeValue("gray.800", "white");

  const features = [
    {
      title: "Cognitive Enhancement",
      description: "Our platform combines spelling with numerical patterns to boost critical thinking and memory retention through engaging puzzles.",
      icon: FaBrain
    },
    {
      title: "Adaptive Learning",
      description: "Dynamic difficulty adjustment ensures optimal challenge levels for continuous improvement and engagement.",
      icon: FaChartLine
    },
    {
      title: "Interactive Gaming",
      description: "Engaging gameplay mechanics make learning fun and effective while improving cognitive abilities.",
      icon: FaGamepad
    },
    {
      title: "Scientific Approach",
      description: "Based on proven cognitive science principles to enhance memory recall and pattern recognition.",
      icon: FaGraduationCap
    }
  ];

  const benefits = [
    "Improved memory retention and recall speed",
    "Enhanced pattern recognition abilities",
    "Better cognitive processing capabilities",
    "Increased focus and concentration",
    "Strengthened problem-solving skills",
    "Better mental agility and flexibility"
  ];

  return (
    <Box py={16}>
      <Container maxW="7xl">
        <MotionElement type="fadeInY">
          <VStack spacing={12}>
            <Box textAlign="center" maxW="3xl" mx="auto">
              <Heading
                as="h1"
                size={{ base: "sm", md: "sm", lg: "lg", xl: "xl" }}
                mb={{ base: 4, md: 6 }}
                color={headingColor}
                bgGradient="linear(to-r, blue.400, purple.500)"
                bgClip="text"
                px={{ base: 4, md: 0 }}
                lineHeight={{ base: "shorter", md: "normal" }}
              >
                About TheLastSpellingBee
              </Heading>
              <Text 
                fontSize={{ base: "lg", md: "xl" }} 
                color={textColor} 
                textAlign={"left"}
                px={{ base: 4, md: 0 }}
                lineHeight="tall"
              >
                <Text as="span" fontWeight="bold">TheLastSpellingBee</Text> is a web-based 
                game designed to enhance cognitive abilities by combining spelling with 
                numerical patterns. It challenges users to think critically and improve 
                memory retention through engaging spelling-based puzzles.
              </Text>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} w="full">
              {features.map((feature, index) => (
                <MotionElement key={index} type="fadeInY">
                  <FeatureCard {...feature} />
                </MotionElement>
              ))}
            </SimpleGrid>

            <Box 
              bg={useColorModeValue("white", "gray.800")}
              p={8}
              borderRadius="xl"
              shadow="xl"
              w="full"
            >
              <Flex align="center" mb={6}>
                <Icon as={FaUsers} w={6} h={6} color="blue.500" mr={3} />
                <Heading as="h2" size="lg" color={headingColor}>
                  Key Benefits
                </Heading>
              </Flex>
              <List spacing={3}>
                {benefits.map((benefit, index) => (
                  <ListItem 
                    key={index}
                    display="flex"
                    alignItems="center"
                    color={textColor}
                  >
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    {benefit}
                  </ListItem>
                ))}
              </List>
            </Box>

            <Text fontSize="lg" color={textColor} textAlign="center">
              Join <Text as="span" fontWeight="bold">TheLastSpellingBee</Text> today 
              and embark on a journey to enhance your cognitive abilities through 
              our scientifically designed challenges!
            </Text>
          </VStack>
        </MotionElement>
      </Container>
    </Box>
  );
};
  