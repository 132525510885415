export const apiUrl = process.env.NODE_ENV === "development"
          ? "http://localhost:5001"
          : "https://thelastspellingbee-api-v1.onrender.com";

export const theLastSpellingBeeReCaptchaKey = '489c375f-3976-4e60-9c87-97423d4c0c16';
// export const config = {
//     baseUrl: {
//       default:
//         process.env.NODE_ENV === "development"
//           ? "http://localhost:5001/api/code-bender/"
//           : "https://thelastspellingbee-api.onrender.com/api/code-bender/",
//     },
//     socketUrl:
//       process.env.NODE_ENV === "development"
//         ? "http://localhost:5001" 
//         : "https://thelastspellingbee-socket.onrender.com", 
//   };
  