import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import ContentCard from "../nav/ContentCard";
import { useApiService } from "../../redux-action/api.service";
import { useState } from "react";
import { FaCoins, FaCreditCard, FaHistory } from "react-icons/fa";
import { motion } from "framer-motion";
import { Background } from "../../common/background/Background";
import { formatNumberWithCommas } from "../../common/utils/currency-formatter";
import { logoutUser } from "../../redux-action/slices/auth-slice";
import { useAppDispatch } from "../../redux-action/hooks/useTypedSelector";
import { useCredits } from "../../hooks/use-credits";

const MotionBox = motion(Box);

const CREDIT_PRICE = 1.44; 

export const Credit = () => {
  const credits = useCredits();
  const api = useApiService();
  const toast = useToast();
  const [creditAmount, setCreditAmount] = useState<number>(10);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  
  const totalPrice = (creditAmount * CREDIT_PRICE).toFixed(2);

  const handlePurchase = async () => {
    try {
      setIsLoading(true);
      
      // Call backend to create Stripe session
      const response = await api.service("payments").create({
        credits: creditAmount,
        amount: parseFloat(totalPrice),
      });

      // Redirect to Stripe checkout
      window.location.href = response.url;
    } catch (error: any) {
       // Check if it's a 401 error user is not authenticated
       if (error?.response?.status === 401 || 
        error?.response?.data?.statusCode === 401 ||
        error?.response?.data?.statusCode === "401") {
          await api.service("auth").signOut();
          await dispatch(logoutUser());
    }
      toast({
        title: "Error",
        description: error.message || "Failed to process payment",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContentCard bg="transparent">
      <Background />
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8} align="stretch">
          {/* Header */}
          <Box textAlign="center">
            <Heading
              as="h1"
              size="2xl"
              mb={4}
              color="white"
              letterSpacing="tight"
            >
              Purchase Credits
            </Heading>
            <Text color="gray.200" fontSize="lg">
              Add credits to your account to create more tests
            </Text>
          </Box>

          <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
            {/* Purchase Form */}
            <MotionBox
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Box
                bg="white"
                p={8}
                borderRadius="xl"
                boxShadow="xl"
              >
                <VStack spacing={6} align="stretch">
                  <HStack spacing={4}>
                    <Icon as={FaCreditCard} boxSize={6} color="blue.500" />
                    <Heading size="md">Buy Credits</Heading>
                  </HStack>

                  <FormControl>
                    <FormLabel>Number of Credits</FormLabel>
                    <NumberInput
                      min={1}
                      value={creditAmount}
                      onChange={(_, value) => setCreditAmount(value)}
                    >
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    <Text fontSize="sm" color="gray.500" mt={2}>
                      ${CREDIT_PRICE} per credit
                    </Text>
                  </FormControl>

                  <Box
                    p={4}
                    bg="blue.50"
                    borderRadius="md"
                    border="1px"
                    borderColor="blue.100"
                  >
                    <Stat>
                      <StatLabel color="blue.700">Total Amount</StatLabel>
                      <StatNumber color="blue.600" fontSize="2xl">
                        ${totalPrice} USD
                      </StatNumber>
                    </Stat>
                  </Box>

                  <Button
                    colorScheme="blue"
                    size="lg"
                    onClick={handlePurchase}
                    isLoading={isLoading}
                    loadingText="Processing..."
                  >
                    Purchase Credits
                  </Button>
                </VStack>
              </Box>
            </MotionBox>

            {/* Info Cards */}
            <VStack spacing={6}>
              {/* Current Balance */}
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                w="full"
              >
                <Box
                  bg="white"
                  p={6}
                  borderRadius="xl"
                  boxShadow="xl"
                >
                  <VStack spacing={4} align="stretch">
                    <HStack justify="space-between">
                      <Heading size="md" color="gray.700">
                        Current Balance
                      </Heading>
                      <Icon as={FaCoins} color="yellow.500" boxSize={6} />
                    </HStack>
                    <Heading size="3xl" color="blue.500">
                      {formatNumberWithCommas(credits ?? 0)}
                    </Heading>
                    <Text color="gray.600">
                      Available credits for creating Memory IQ tests
                    </Text>
                  </VStack>
                </Box>
              </MotionBox>

              {/* Credit Usage Info */}
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                w="full"
              >
                <Box
                  bg="white"
                  p={6}
                  borderRadius="xl"
                  boxShadow="xl"
                >
                  <VStack spacing={4} align="stretch">
                    <HStack justify="space-between">
                      <Heading size="md" color="gray.700">
                        Credit Usage
                      </Heading>
                      <Icon as={FaHistory} color="purple.500" boxSize={6} />
                    </HStack>
                    <Text color="gray.600">
                      Credits are used to create new Memory IQ tests. Each test requires 1 credit.
                      Credits never expire and can be used at any time.
                    </Text>
                  </VStack>
                </Box>
              </MotionBox>
            </VStack>
          </SimpleGrid>
        </VStack>
      </Container>
    </ContentCard>
  );
};
