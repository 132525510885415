import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Divider,
  UnorderedList,
  ListItem,
  useColorModeValue,
  Link,
} from "@chakra-ui/react";
import { MotionElement } from "../../components/MotionElement";

export const PrivacyPolicy = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const headingColor = useColorModeValue("gray.800", "white");

  return (
    <Box bg={bgColor} py={20}>
      <Container
        maxW="4xl"
        bg={"white"}
        borderRadius={"lg"}
        p={8}
        boxShadow={"lg"}
      >
        <MotionElement type="fadeInY">
          <VStack spacing={8} align="stretch">
            <Heading
              as="h1"
              size="xl"
              textAlign="center"
              color={headingColor}
              mb={8}
            >
              Privacy Policy
            </Heading>

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                Introduction
              </Heading>
              <Text color={textColor} mb={4}>
                Welcome to <Text as="span" fontWeight={"bold"}>TheLastSpellingBee</Text>, a platform dedicated to enhancing
                cognitive abilities through interactive memory and IQ tests.
                This Privacy Policy explains how we collect, use, and protect
                your personal information when you engage with our services.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                Data Collection and Protection
              </Heading>
              <Text color={textColor} mb={4}>
                The security of your personal information is our priority. We
                implement industry-standard security measures to protect your
                data from unauthorized access, disclosure, or misuse. While we
                strive to safeguard your information, we cannot guarantee
                complete security due to the inherent risks of internet
                transmission.
              </Text>
              <Text color={textColor} mb={4}>
                When you interact with <Text as="span" fontWeight={"bold"}>TheLastSpellingBee</Text>, we may collect the
                following information:
              </Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>
                  Your name and email address (for account creation and login)
                </ListItem>
                <ListItem>Memory IQ test results and performance data</ListItem>
                <ListItem>User basic information</ListItem>
                <ListItem>Usage patterns to improve user experience</ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                How We Use Your Information
              </Heading>
              <Text color={textColor} mb={4}>
                Your data is utilized to enhance your experience on
                <Text as="span" fontWeight={"bold"}>TheLastSpellingBee</Text>. Specifically, we use your information to:
              </Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>
                  Provide and improve our interactive IQ and memory tests
                </ListItem>
                <ListItem>Personalize your user experience</ListItem>
                <ListItem>
                  Generate and store test results for your progress tracking
                </ListItem>
                <ListItem>Enable secure account authentication</ListItem>
                <ListItem>
                  Send important updates and notifications about the platform
                </ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                Data Sharing and Third Parties
              </Heading>
              <Text color={textColor} mb={4}>
                We do not sell or rent your personal information to third
                parties. However, we may share your data in the following cases:
              </Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>
                  Compliance with legal obligations or court orders
                </ListItem>
                <ListItem>Fraud prevention and security enforcement</ListItem>
                <ListItem>
                  Integration with trusted partners (e.g., payment processors)
                </ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                User Consent and Control
              </Heading>
              <Text color={textColor} mb={4}>
                By using TheLastSpellingBee, you consent to our data practices
                outlined in this Privacy Policy. You have control over your
                data, including:
              </Text>
              <UnorderedList spacing={2} pl={5} color={textColor} mb={4}>
                <ListItem>
                  Requesting access to your personal information
                </ListItem>
                <ListItem>
                  Updating or correcting inaccuracies in your data
                </ListItem>
                <ListItem>
                  Requesting the deletion of your account and associated data
                </ListItem>
              </UnorderedList>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                Changes to This Policy
              </Heading>
              <Text color={textColor} mb={4}>
                We may update this Privacy Policy periodically. Any
                modifications will be reflected on this page with an updated
                "Last Modified" date. If significant changes occur, we will
                notify users through email or in-app notifications.
              </Text>
            </Box>

            <Divider />

            <Box>
              <Heading as="h2" size="lg" mb={4} color={headingColor}>
                Contact Us
              </Heading>
              <Text color={textColor} mb={4}>
                If you have any questions about this Privacy Policy or your data, please:
              </Text>
              
              <Link 
                href="/contact-us" 
                color="blue.500" 
                fontWeight="bold"
                _hover={{ textDecoration: 'underline', color: 'blue.600' }}
              >
                Contact our Support Team
              </Link>
            </Box>

            <Box pt={8}>
              <Text color={textColor} fontSize="sm">
                Last updated: {new Date().toLocaleDateString()}
              </Text>
            </Box>
          </VStack>
        </MotionElement>
      </Container>
    </Box>
  );
};
