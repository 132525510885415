import { FC } from "react";
import { Box, Heading, Progress, Stack, Text, VStack } from "@chakra-ui/react";
import { Question } from "./Question";

type QuestionsCardProps = {
  defaultInstruction: string;
  instruction: string;
  question: string | string[];
};

export const QuestionsCard: FC<QuestionsCardProps> = ({
  defaultInstruction,
  instruction,
  question,
}) => {
  return (
    <Box width={"100%"}>
      <Box
        bg="rgba(255, 255, 255, 0.9)"
        width="100%"
        borderRadius="xl"
        overflow="hidden"
        boxShadow="lg"
      >
        <Stack
          bg={["blue.700"]}
          p={{ base: "16px 16px 7px 16px", sm: "24px 24px 12px 24px" }}
          mb={4}
          alignItems="start"
        >
          <Stack
            w="full"
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Stack direction="row" alignItems="center" borderRadius="md">
              <Text color="white" fontWeight="bold" fontSize="xl" opacity={1}>
                {defaultInstruction}
              </Text>
            </Stack>
          </Stack>

          <Text
            fontWeight="bold"
            fontSize={{ base: "xl", sm: "3xl" }}
            color="whiteAlpha.800"
            lineHeight="6"
            opacity={1}
          >
            {instruction}
          </Text>
          <Progress
            w="full"
            value={Math.round((Date.now() % 26000) / 260)}
            size="xs"
            background="blackAlpha.400"
            sx={{
              "&>div": {
                bg: "white",
                transition: "width 26s linear",
                animation: "slideProgress 26s linear infinite",
              },
              "@keyframes slideProgress": {
                "0%": { width: "0%" },
                "100%": { width: "100%" },
              },
            }}
          />
        </Stack>
        <VStack spacing={6} p={8}>
          <Heading fontSize={["3xl", "4xl"]} textAlign="center">
            {Object.keys(question).map((key, index) => (
              <Question key={index} question={question[index]} index={index} />
            ))}
            ?
          </Heading>
        </VStack>
      </Box>
    </Box>
  );
};
