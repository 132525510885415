import {
  Box,
  Container,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  VStack,
  SimpleGrid,
  Text,
  FormErrorMessage,
  Divider,
  Avatar,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import ContentCard from "../nav/ContentCard";
import { useUser } from "../../hooks/use-user";
import { useApiService } from "../../redux-action/api.service";
import { UserSchema, PasswordSchema } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Background } from "../../common/background/Background";
import { FaCrown, FaCoins } from "react-icons/fa";
import { formatNumberWithCommas } from "../../common/utils/currency-formatter";

export const Settings = () => {
  const { user } = useUser();
  const api = useApiService();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<UserSchema>({
    resolver: yupResolver(UserSchema),
    defaultValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
    },
  });

  const {
    handleSubmit: handlePasswordSubmit,
    register: registerPassword,
    reset: resetPassword,
    formState: { isSubmitting: isPasswordSubmitting, errors: passwordErrors },
  } = useForm<PasswordSchema>({
    resolver: yupResolver(PasswordSchema),
  });

  useEffect(() => {
    if (user) {
      reset({
        firstName: user.firstName,
        lastName: user.lastName,
      });
    }
  }, [user, reset]);

  const onSubmitHandler = async (data: UserSchema) => {
    try {
      if (!user?.id) {
        throw new Error("User ID is required");
      }

      await api.service("users").patch(user.id, data);

      toast({
        title: "Success",
        description: "Your profile has been updated successfully",
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });

      reset(data);
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to update profile",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onPasswordSubmit = async (data: PasswordSchema) => {
    try {
      if (!user?.id) {
        throw new Error("User ID is required");
      }

      await api.service("users").patch(user.id, {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      });

      toast({
        title: "Success",
        description: "Your password has been updated successfully",
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });

      resetPassword();
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message || "Failed to update password",
        status: "error",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <ContentCard bg="transparent">
      <Background />
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8}>
          {/* Header */}
          <Box textAlign="center" w="full">
            <Heading
              as="h1"
              size="2xl"
              mb={4}
              color="white"
              letterSpacing="tight"
            >
              Account Settings
            </Heading>
            <Text color="gray.200" fontSize="lg">
              Manage your profile and preferences
            </Text>
          </Box>

          <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={8} w="full">
            {/* Profile Form */}
            <Box
              gridColumn={{ lg: "span 2" }}
              as="form"
              onSubmit={handleSubmit(onSubmitHandler)}
              bg="white"
              p={8}
              borderRadius="xl"
              boxShadow="xl"
            >
              <VStack spacing={6} align="stretch">
                {/* Profile Header */}
                <HStack spacing={4}>
                  <Avatar
                    size="xl"
                    name={`${user?.firstName} ${user?.lastName}`}
                    src={""}
                  />
                  <VStack align="start" spacing={1}>
                    <Heading size="md">Profile Information</Heading>
                    <Text color="gray.600">
                      Update your personal information
                    </Text>
                  </VStack>
                </HStack>

                <Divider />

                {/* Form Fields */}
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                  <FormControl isInvalid={!!errors.firstName}>
                    <FormLabel>First Name</FormLabel>
                    <Input {...register("firstName")} />
                    <FormErrorMessage>
                      {errors.firstName?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!errors.lastName}>
                    <FormLabel>Last Name</FormLabel>
                    <Input {...register("lastName")} />
                    <FormErrorMessage>
                      {errors.lastName?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      value={user?.email}
                      disabled
                    />
                  </FormControl>
                </SimpleGrid>

                <Button
                  type="submit"
                  colorScheme="blue"
                  size="lg"
                  isLoading={isSubmitting}
                  loadingText="Saving..."
                >
                  Save Changes
                </Button>
              </VStack>
            </Box>
 {/* Credits & Plan Info */}
 <VStack spacing={6}>
              {/* Credits Card */}
              <Box bg="white" p={6} borderRadius="xl" boxShadow="xl" w="full">
                <VStack spacing={4} align="stretch">
                  <HStack justify="space-between">
                    <Heading size="md" color="gray.700">
                      Credits
                    </Heading>
                    <Icon as={FaCoins} color="yellow.500" boxSize={6} />
                  </HStack>
                  <Heading size="3xl" color="blue.500">
                    {formatNumberWithCommas(user?.credit ?? 0)}
                  </Heading>
                  <Text color="gray.600">
                    Available credits for creating tests
                  </Text>
                </VStack>
              </Box>

              {/* Plan Info */}
              <Box bg="white" p={6} borderRadius="xl" boxShadow="xl" w="full">
                <VStack spacing={4} align="stretch">
                  <HStack justify="space-between">
                    <Heading size="md" color="gray.700">
                      Current Plan
                    </Heading>
                    <Icon as={FaCrown} color="purple.500" boxSize={6} />
                  </HStack>
                  <Heading
                    size="lg"
                    color="purple.500"
                    textTransform="capitalize"
                  >
                    {user?.accountType ?? "Free"}
                  </Heading>
                  <Text color="gray.600">Your current subscription plan</Text>
                </VStack>
              </Box>
            </VStack>
            {/* Password Update Form */}
            <Box
              gridColumn={{ lg: "span 2" }}
              as="form"
              onSubmit={handlePasswordSubmit(onPasswordSubmit)}
              bg="white"
              p={8}
              borderRadius="xl"
              boxShadow="xl"
              mt={8}
            >
              <VStack spacing={6} align="stretch">
                <HStack spacing={4}>
                  <VStack align="start" spacing={1}>
                    <Heading size="md">Change Password</Heading>
                    <Text color="gray.600">
                      Update your password to keep your account secure
                    </Text>
                  </VStack>
                </HStack>

                <Divider />

                <VStack spacing={4}>
                  <FormControl isInvalid={!!passwordErrors.currentPassword}>
                    <FormLabel>Current Password</FormLabel>
                    <Input 
                      type="password" 
                      {...registerPassword("currentPassword")} 
                    />
                    <FormErrorMessage>
                      {passwordErrors.currentPassword?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!passwordErrors.newPassword}>
                    <FormLabel>New Password</FormLabel>
                    <Input 
                      type="password" 
                      {...registerPassword("newPassword")} 
                    />
                    <FormErrorMessage>
                      {passwordErrors.newPassword?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={!!passwordErrors.confirmPassword}>
                    <FormLabel>Confirm New Password</FormLabel>
                    <Input 
                      type="password" 
                      {...registerPassword("confirmPassword")} 
                    />
                    <FormErrorMessage>
                      {passwordErrors.confirmPassword?.message}
                    </FormErrorMessage>
                  </FormControl>
                </VStack>

                <Button
                  type="submit"
                  colorScheme="blue"
                  size="lg"
                  isLoading={isPasswordSubmitting}
                  loadingText="Updating..."
                >
                  Update Password
                </Button>
              </VStack>
            </Box>

           
          </SimpleGrid>
        </VStack>
      </Container>
    </ContentCard>
  );
};
