import { Box, Container } from "@chakra-ui/react";
import { IQScoreExplanation } from "./IQScoreExplanation";
import { ScientificValidation } from "./ScientificValidation";
import { AboutTheLastSpellingBeeIQ } from "./AboutTheLastSpellingBeeIQ";
import ContentCard from "../../nav/ContentCard";

export const AboutTheLastSpellingBee = () => {
  return (
    <ContentCard>
      <Container maxW="7xl" pt={8}>
        <Box id="about" bg="#FFF">
          <AboutTheLastSpellingBeeIQ />
        </Box>
        <Box id="scientific-validation" bg="#f5f5f9">
          <ScientificValidation />
        </Box>
        <Box id="iq-score" bg="#FFF">
          <IQScoreExplanation />
        </Box>
      </Container>
    </ContentCard>
  );
};
