import {
  Flex,
  Text,
  VStack,
  SimpleGrid,
  Heading,
  Spinner,
  Button,
  ButtonGroup,
  Container,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC } from "react";
import { useLatestResults } from "../../../hooks/use-latest-results";
import ContentCard from "../../nav/ContentCard";
import { Score } from "../../../types/score-types";
import { ScoreCard } from "../../spelling/IQScore/ScoreCard";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

type DisplayLatestResultsProps = {
  paginatePerPage?: number;
  mt?: number;
};

export const DisplayLatestResults: FC<DisplayLatestResultsProps> = ({
  paginatePerPage,
  mt = 20,
}) => {
  const { isLoading, paginate, currentItems, scores, itemsPerPage } =
    useLatestResults(paginatePerPage);

  const bgGradient = useColorModeValue(
    "linear(to-r, blue.400, purple.500)",
    "linear(to-r, blue.600, purple.700)"
  );

  if (isLoading) {
    return (
      <Flex justify="center" align="center" minH="50vh">
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Flex>
    );
  }

  return (
    <ContentCard bg="transparent">
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8}>
          {/* Header Section */}
          <Box textAlign="center" w="full">
            <Heading
              as="h1"
              size="2xl"
              mb={4}
              bgGradient={bgGradient}
              bgClip="text"
              letterSpacing="tight"
              color="white"
            >
              Latest Memory IQ Results
            </Heading>
            <Text color="gray.200" fontSize="lg" maxW="2xl" mx="auto">
              Check out the most recent achievements from our community members
            </Text>
          </Box>

          {/* Results Grid */}
          <SimpleGrid
            columns={{ base: 1, lg: 2 }}
            spacing={6}
            w="full"
            px={{ base: 4, md: 8 }}
          >
            {currentItems?.map((score: Score, index: number) => (
              <MotionBox
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <ScoreCard
                  score={score}
                  onViewCertificate={() => {}}
                  showCertificateButton={false}
                />
              </MotionBox>
            ))}
          </SimpleGrid>

          {/* Pagination */}
          {scores.length > itemsPerPage && (
            <Box 
              mt={8} 
              p={4} 
              borderRadius="lg" 
              bg="whiteAlpha.100"
              backdropFilter="blur(10px)"
            >
              <ButtonGroup spacing={2} variant="outline">
                {Array.from({
                  length: Math.ceil(scores.length / itemsPerPage),
                }).map((_, index) => (
                  <Button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    colorScheme="blue"
                    size="md"
                    variant={currentItems[0] && 
                      currentItems[0] === scores[index * itemsPerPage] 
                      ? "solid" 
                      : "outline"
                    }
                    _hover={{
                      transform: "translateY(-2px)",
                      shadow: "lg",
                    }}
                    transition="all 0.2s"
                  >
                    {index + 1}
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
          )}
        </VStack>
      </Container>
    </ContentCard>
  );
};
