import {
  Heading,
  Button,
  useToast,
  Center,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ContentCard from "../../nav/ContentCard";
import { CertificateModal } from "./CertificateModal";
import { Background } from "../../../common/background/Background";
import { Score } from "../../../types/score-types";
import { api } from "../../../redux-action/api.service";
import { useParams } from "react-router-dom";

export const GetCertificate = () => {
  const { email, gameScoreId } = useParams();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [score, setScore] = useState<Score>();

  const toast = useToast();

  const getCertificate = async (email: string, gameScoreId: string) => {
    try {
      setIsLoading(true);
      const response = await api
        .service(`game-scores/email/${email}/${gameScoreId}`)
        .find();
      setScore(response);
      setIsModalOpen(true);
      setIsLoading(false);
    } catch (error: any) {
      toast({
        title: "Error",
        description:
          "An error occurred while fetching your Memory IQ score. Please try again.",
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (email && gameScoreId) {
      getCertificate(email, gameScoreId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, gameScoreId]);

  const refreshPage = () => {
    window.location.reload();
  };

  if (isLoading) {
    return (
      <Center>
        <Alert status="info">
          <AlertIcon />
          Loading Please wait...
        </Alert>
      </Center>
    );
  }
  return (
    <ContentCard bg={"transparent"}>
      <Background />
      <Heading textAlign="center" mb={5} mt={20} color={"white"}>
        Your Memory IQ Score Certificate
      </Heading>
      <Center>
        <Button onClick={refreshPage}>Get Certificate</Button>
      </Center>
      {!score ? (
        <Alert status="info" mt={5}>
          <AlertIcon />
          {email ? `No IQ certificate found for ${email}!` : ""}
        </Alert>
      ) : (
        <>
          <CertificateModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            score={score ?? ({} as Score)}
          />
        </>
      )}
    </ContentCard>
  );
};
