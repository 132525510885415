import {
  Box,
  Heading,
  Text,
  Img,
  Button,
  Link,
  Container,
  VStack,
  SimpleGrid,
  Icon,
  useColorModeValue,
  HStack,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { RecaptchaHero } from "./RecaptchaHero";
import { MotionElement } from "../../components/MotionElement";
import ContentCard from "../nav/ContentCard";
import { ApiReferenceMenu } from "../nav/ApiReferenceMenu";
import { FaShieldAlt, FaRobot, FaCode, FaNpm } from "react-icons/fa";

type FeatureProps = {
  title: string;
  text: string;
  icon: React.ElementType;
};

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <VStack
      bg={useColorModeValue("white", "gray.800")}
      p={6}
      rounded="xl"
      shadow="md"
      borderWidth={1}
      borderColor={useColorModeValue("gray.100", "gray.700")}
      spacing={3}
      transition="transform 0.2s"
      _hover={{ transform: "translateY(-5px)" }}
    >
      <Icon as={icon} w={8} h={8} color="blue.500" />
      <Heading size="md">{title}</Heading>
      <Text textAlign="center" color="gray.600">
        {text}
      </Text>
    </VStack>
  );
};

export const AboutRecaptcha = () => {
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <ContentCard>
      <ApiReferenceMenu />
      <Container maxW="container.xl" py={8}>
        <VStack spacing={12}>
          {/* Hero Section */}
          <MotionElement type="fadeInY">
            <VStack spacing={6} textAlign="center">
              <Heading
                as="h1"
                size="2xl"
                bgGradient="linear(to-r, blue.400, purple.500)"
                bgClip="text"
              >
                TheLastSpellingBee Re-captcha
              </Heading>
              <Text fontSize="xl" color="gray.600" maxW="3xl">
                A modern, intelligent security solution that protects your website
                from spam and abuse while providing a seamless user experience.
              </Text>
              <HStack spacing={4}>
                <Link href="/api-reference">
                  <Button colorScheme="blue" size="lg">
                    Get Started
                  </Button>
                </Link>
                <Link href="https://www.npmjs.com/package/the-last-spelling-bee-re-captcha" isExternal>
                  <Button leftIcon={<FaNpm />} colorScheme="gray" size="lg" variant="outline">
                    NPM Package
                  </Button>
                </Link>
              </HStack>
            </VStack>
          </MotionElement>

          {/* Features Grid */}
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full">
            <Feature
              icon={FaShieldAlt}
              title="Advanced Protection"
              text="Secure your website with our AI-powered verification system that effectively distinguishes between humans and bots."
            />
            <Feature
              icon={FaRobot}
              title="Bot Prevention"
              text="Stop automated attacks, prevent spam submissions, and maintain the integrity of your website's data."
            />
            <Feature
              icon={FaCode}
              title="Easy Integration"
              text="Simple implementation with our NPM package 'the-last-spelling-bee-re-captcha' and comprehensive API."
            />
          </SimpleGrid>

          {/* Main Content */}
          <Box
            bg="white"
            p={8}
            rounded="xl"
            shadow="md"
            borderWidth={1}
            borderColor={borderColor}
          >
            <VStack spacing={6} align="start">
              <Text fontSize="lg">
                Fraud on the internet costs businesses billions of dollars
                annually. TheLastSpellingBee Re-captcha provides enterprise-grade
                security through our unique A1-B2-C3-D4 IQ questions system.
              </Text>

              <Box w="full" py={8}>
                <MotionElement type="fadeInX">
                  <Img src="images/advert.gif" mx="auto" />
                </MotionElement>
              </Box>

              <Box fontSize="lg" textAlign="left">
                Our service helps defend against common web-based attacks,
                including:
                <UnorderedList mt={2} spacing={2}>
                  <ListItem>Credential stuffing</ListItem>
                  <ListItem>Account takeovers</ListItem>
                  <ListItem>Automated scraping</ListItem>
                  <ListItem>Bot attacks</ListItem>
                </UnorderedList>
              </Box>

              <Text fontSize="lg">
                With over 5 million protected sites, TheLastSpellingBee Re-captcha
                ensures security without interrupting the user experience,
                making it perfect for implementation across all customer
                touchpoints.
              </Text>
            </VStack>
          </Box>

          {/* Demo Section */}
          <Box w="full">
            <MotionElement type="fadeInY">
              <RecaptchaHero />
            </MotionElement>
          </Box>
        </VStack>
      </Container>
    </ContentCard>
  );
};
