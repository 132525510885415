import { FC, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Card,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  Button,
  Link,
  Badge,
  Icon,
  HStack,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { ShareLinks } from "../../components/ShareLinks";
import { ArrowForwardIcon, TimeIcon, CalendarIcon, HamburgerIcon } from "@chakra-ui/icons";
import { FaUsers, FaQuestionCircle, FaPlay } from "react-icons/fa";
import dateFormat from "dateformat";
import { EditGame } from "./EditGame";
import { DeleteGame } from "./DeleteGame";
import { getExDate, getLevel, isExpired } from "../spelling/game-play/helper";
import { Game } from "../../types/game-type";
import { useNavigate } from "react-router-dom";
import { addSpaceAfterEachWord } from "../../common/utils/helperFuntions";
import { GameLevelExplanation } from "../../components/GameLevelExplanation";
import { getInviteLink } from "../../apps/spelling/game-play/helper";

type GameCardProps = {
  game: Game;
  _hover?: any;
  transition?: string;
}

export const GameCard: FC<GameCardProps> = ({ game }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const levelName = getLevel(game.questionType);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isGameExpired = isExpired(new Date(game.expiryDate ?? ""));
  const navigate = useNavigate();

  const handleTakeTest = () => {
    navigate(`/auth/game/${game.id}`);
  };

  return (
    <Card
      p={0}
      my={{ base: 4, xl: 0 }}
      boxShadow="lg"
      borderRadius="xl"
      bg="gray.50"
      position="relative"
      transition="all 0.2s"
      overflow="hidden"
      _hover={{ transform: 'translateY(-2px)', boxShadow: '2xl' }}
    >
      {/* Header Section with Gradient Background */}
      <Box 
        bg="linear-gradient(45deg, blue.600, purple.500)" 
        p={6}
      >
        <Flex justify="space-between" align="start">
          <VStack align="start" spacing={2}>
            <Heading 
              size="md" 
              isTruncated 
              textShadow="0 1px 2px rgba(0,0,0,0.2)"
            >
              {game.name}
            </Heading>
            <HStack spacing={2}>
              <Badge
                bg="blue.400"
                color="gray.50"
                px={3}
                py={1}
                borderRadius="full"
                fontSize="sm"
                boxShadow="0 1px 2px rgba(0,0,0,0.1)"
              >
                {levelName}
              </Badge>
              <Button
                variant="ghost"
                leftIcon={<FaQuestionCircle />}
                color="blue.500"
                onClick={onOpen}
                _hover={{ bg: "transparent", color: "blue.600" }}
              >
                Learn more
              </Button>
            </HStack>
          </VStack>
          <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
            <MenuButton
              as={Button}
              variant="ghost"
              size="sm"
              _hover={{ bg: 'whiteAlpha.300' }}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <HamburgerIcon />
            </MenuButton>
            <MenuList bg="white" shadow="lg">
              <EditGame game={game} />
              <DeleteGame game={game} />
            </MenuList>
          </Menu>
        </Flex>
      </Box>

      {/* Game Details Section */}
      <Box p={6} bg="white">
        <SimpleGrid columns={2} spacing={6} mb={6}>
          <VStack align="start" spacing={1}>
            <HStack color="blue.600" fontSize="sm">
              <Text fontWeight="medium">Category</Text>
            </HStack>
            <Text color="gray.700">{addSpaceAfterEachWord(game.gameCategory)}</Text>
          </VStack>

          <VStack align="start" spacing={1}>
            <HStack color="blue.600" fontSize="sm">
              <TimeIcon />
              <Text fontWeight="medium">Time Limit</Text>
            </HStack>
            <Text color="gray.700">{game.gameTime} Minutes</Text>
          </VStack>

          <VStack align="start" spacing={1}>
            <HStack color="blue.600" fontSize="sm">
              <Icon as={FaUsers} />
              <Text fontWeight="medium">Expected Users</Text>
            </HStack>
            <Text color="gray.700">{game.expectedUsers}</Text>
          </VStack>

          <VStack align="start" spacing={1}>
            <HStack color="blue.600" fontSize="sm">
              <CalendarIcon />
              <Text fontWeight="medium">Expires</Text>
            </HStack>
            <Text color="gray.700">
              {dateFormat(getExDate(game.expiryDate ?? ""), "mmm dS, yyyy")}
            </Text>
          </VStack>
        </SimpleGrid>

        {/* Progress Section */}
        <Box mb={6}>
          <HStack justify="space-between" mb={2}>
            <Text fontSize="sm" color="blue.600" fontWeight="medium">Users Attempted</Text>
            <Text fontSize="sm" color="gray.600">
              {game.scores?.length ?? 0} of {game.expectedUsers}
            </Text>
          </HStack>
          <Box bg="gray.100" borderRadius="full" h="3">
            <Box
              bg="blue.500"
              h="100%"
              borderRadius="full"
              width={`${((game.scores?.length ?? 0) / game.expectedUsers) * 100}%`}
              transition="width 0.3s ease"
            />
          </Box>
        </Box>

        {/* Actions Section */}
        <VStack spacing={4}>
        
            <>
              <Button
                as={"a"}
                href={`${getInviteLink(game.id?.toString() ?? "")}`}
                target="_blank"
                leftIcon={<Icon as={FaPlay} />}
                colorScheme="blue"
                onClick={handleTakeTest}
                isDisabled={isGameExpired}
                size="lg"
                w="full"
                borderRadius="full"
                shadow="md"
                _hover={{ transform: 'translateY(-1px)', shadow: 'lg' }}
              >
                Take Test
              </Button>
              {(game.scores?.length ?? 0) > 0 && (
                <Link href={`/auth/game-result/${game.id}`} width="100%">
                  <Button
                    size="md"
                    rightIcon={<ArrowForwardIcon />}
                    variant="outline"
                    w="full"
                    borderRadius="full"
                    colorScheme="blue"
                  >
                    View Results
                  </Button>
                </Link>
              )}
            </>
       
          <Box w="full" pt={2}>
          {isGameExpired ? (
            <Badge 
              colorScheme="red" 
              p={3} 
              borderRadius="md" 
              width="100%" 
              textAlign="center"
              fontSize="sm"
            >
              Test Expired
            </Badge>
          ) : (
              <ShareLinks gameId={game.id?.toString() ?? ""} />
            )}
          </Box>
        </VStack>
      </Box>
      <GameLevelExplanation isOpen={isOpen} onClose={onClose} />
    </Card>
  );
};
