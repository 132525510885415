export type AgeGroupOption = {
  value: string;
  label: string;
};

export const AgeGroups: AgeGroupOption[] = [
  { value: '0-5', label: '0 to 5' },
  { value: '6-12', label: '6 to 12' },
  { value: '13-18', label: '13 to 18' },
  { value: '19-25', label: '19 to 25' },
  { value: '26-35', label: '26 to 35' },
  { value: '36-45', label: '36 to 45' },
  { value: '46-55', label: '46 to 55' },
  { value: '56-65', label: '56 to 65' },
  { value: '66+', label: '66 and above' },
];
