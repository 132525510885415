import { useState, useEffect, useCallback } from "react";
import { useGame } from "./use-game";
import { useAppDispatch } from "../redux-action/hooks/useTypedSelector";
import { setTimeCompleted } from "../redux-action/slices/game-slice";
import { useGameComplete } from "./useGameComplete";

export const formatTime = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours}:${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds,
  ).padStart(2, "0")}`;
};

export const useGameTimer = () => {
  const { game } = useGame();
  const dispatch = useAppDispatch();
  const { gameComplete } = useGameComplete();
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isTimerLoading, setIsTimerLoading] = useState<boolean>(false);

  const handleGameOver = useCallback(async (formattedTime: string) => {
    try {
      setIsTimerLoading(true);
      await gameComplete(formattedTime);
    } catch (error: any) {
      console.error("Error completing game:", error.message);
    } finally {
      setIsTimerLoading(false);
    }
  }, [gameComplete]);

  useEffect(() => {
    if (game && !isInitialized) {
      const startTimeKey = `game_start_time_${game.id}`;
      const storedStartTime = localStorage.getItem(startTimeKey);

      if (storedStartTime) {
        const elapsedSeconds = Math.floor(
          (Date.now() - parseInt(storedStartTime)) / 1000,
        );
        const totalGameSeconds = game.gameTime * 60;
        const remainingTime = Math.max(totalGameSeconds - elapsedSeconds, 0);
        setTimeRemaining(remainingTime);
        dispatch(setTimeCompleted(formatTime(remainingTime)));
      } else {
        localStorage.setItem(startTimeKey, Date.now().toString());
        setTimeRemaining(game.gameTime * 60);
      }

      setIsInitialized(true);
    }
  }, [game, isInitialized, dispatch]);

  useEffect(() => {
    if (timeRemaining === null || !isInitialized || !game) {
      return;
    }

    if (timeRemaining === 0) {
      handleGameOver(formatTime(timeRemaining));
      return;
    }

    const timerId = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === null) return null;
        if (prevTime <= 0) return 0;
        return prevTime - 1;
      });
    }, 1000);

    const updateTimeId = setInterval(() => {
      if (timeRemaining > 0) {
        dispatch(setTimeCompleted(formatTime(timeRemaining)));
      }
    }, 5000);

    return () => {
      clearInterval(timerId);
      clearInterval(updateTimeId);
    };
  }, [timeRemaining, isInitialized, game, handleGameOver, dispatch]);

  const handleGameComplete = async () => {
    await handleGameOver(formatTime(timeRemaining ?? 0));
  }

  const totalTime = game?.gameTime ? game.gameTime * 60 : 0;
  const progress = ((timeRemaining ?? 0) / totalTime) * 100;
  const isLowTime = (timeRemaining ?? 0) <= 30;

  return {
    timeRemaining,
    progress,
    isLowTime,
    isTimerLoading,
    game,
    handleGameComplete
  };
}; 