import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types/user-types';
import { Score } from '../../types/score-types';
import { Question } from '../../types/question-type';
import { Game } from '../../types/game-type';


export type GameState = {
  instruction: string;
  gameScore: Score | null;
  user: User | null;
  questions: Question[];
  isLoading: boolean;
  error: string | null;
  game: Game | null;
  currentScore: number;
  timeCompleted: string;
}

const initialState: GameState = {
  instruction: '',
  gameScore: null,
  user: null,
  questions: [],
  isLoading: false,
  error: null,
  currentScore: 0,
  timeCompleted: '',
  game: null,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGameData: (state, action: PayloadAction<{
      instruction: string;
      gameScore: Score;
      user: User;
      questions: Question[];
      game: Game;
    }>) => {
      state.instruction = action.payload.instruction;
      state.gameScore = action.payload.gameScore;
      state.user = action.payload.user;
      state.questions = action.payload.questions;
      state.currentScore = 0;
      state.timeCompleted = '';
      state.game = action.payload.game;
    },
    setCurrentScore: (state, action: PayloadAction<number>) => {
      state.currentScore = action.payload;
    },
    setTimeCompleted: (state, action: PayloadAction<string>) => {
      state.timeCompleted = action.payload;
    },
    resetGame: () => initialState,
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setGameData,
  resetGame,
  setLoading,
  setError,
  setCurrentScore,
  setTimeCompleted,
} = gameSlice.actions;

export default gameSlice.reducer; 