import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Select,
  FormErrorMessage,
  Button,
  useDisclosure,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  FormHelperText,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Control, Controller } from "react-hook-form";
import { GameSchema } from "../apps/game/Schema";
import {
  CATEGORY_OPTIONS,
  QUESTION_LEVEL_LABELS,
  QuestionType,
} from "../types/questions.type";
import { GameLevelExplanation } from "./GameLevelExplanation";
import { FaQuestionCircle } from "react-icons/fa";
import { useUser } from "../hooks/use-user";
import { useState } from "react";
import { formatNumberWithCommas } from "../common/utils/currency-formatter";

type CreateGameFormProps = {
  register: any;
  errors: any;
  control: Control<GameSchema>;
  isSubmitting: boolean;
  actionType?: "create" | "edit";
  expectedUsers?: number;
};

export const CreateGameForm: React.FC<CreateGameFormProps> = ({
  register,
  errors,
  control,
  isSubmitting,
  actionType = "create",
  expectedUsers = 0,
}) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [creditsNeeded, setCreditsNeeded] = useState<number>(1);

  // Handle expected users change
  const handleExpectedUsersChange = (value: string) => {
    const numUsers = parseInt(value) || 1;
    if (actionType === "create") {
      setCreditsNeeded(numUsers);
    } else {
      setCreditsNeeded(numUsers - expectedUsers);
    }
  };
  const questionCountOptions = Array.from(
    { length: Math.floor((100 - 20) / 5) + 1 },
    (_, i) => 20 + i * 5
  );



  return (
    <Stack spacing={7}>
      {/* Game Name */}
      <FormControl isRequired isInvalid={!!errors.name}>
        <FormLabel>Title</FormLabel>
        <Input {...register("name")} placeholder="Enter Title" />
        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
      </FormControl>

      {/* Game Category */}
      <FormControl isRequired isInvalid={!!errors.gameCategory}>
        <FormLabel>Why are you creating this IQ Test?</FormLabel>
        <Select {...register("gameCategory")} placeholder="Select category">
          {CATEGORY_OPTIONS.map((category) => (
            <option key={category} value={category}>
              For {category.replace(/([A-Z])/g, " $1").trim()}
            </option>
          ))}
        </Select>
        <FormErrorMessage>{errors.gameCategory?.message}</FormErrorMessage>
      </FormControl>

      {/* Question Level and Word Length - Side by Side */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        <FormControl isRequired isInvalid={!!errors.questionType}>
          <FormLabel>
            Question Level
            <Button
              variant="ghost"
              leftIcon={<FaQuestionCircle />}
              color="blue.500"
              onClick={onOpen}
              _hover={{ bg: "transparent", color: "blue.600" }}
            >
              Learn more
            </Button>
          </FormLabel>
          <Select {...register("questionType")} placeholder="Select difficulty">
            {QuestionType.map((type) => (
              <option key={type} value={type}>
                {QUESTION_LEVEL_LABELS[type]}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.questionType?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.wordLength}>
          <FormLabel mt={3}>Word Length</FormLabel>
          <Controller
            name="wordLength"
            control={control}
            render={({ field }) => (
              <Select {...field} placeholder="Select word length">
                {[...Array(10)].map((_, i) => (
                  <option key={i + 1} value={i + 1}>
                    {i + 1} characters
                  </option>
                ))}
              </Select>
            )}
          />
          <FormErrorMessage>{errors.wordLength?.message}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      {/* Game Time and Number of Questions - Side by Side */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        <FormControl isRequired isInvalid={!!errors.gameTime}>
          <FormLabel>Game Time</FormLabel>
          <Controller
            name="gameTime"
            control={control}
            defaultValue={2}
            render={({ field }) => (
              <Select {...field} placeholder="Select duration">
                {[...Array(30)].map((_, i) => (
                  <option key={(i + 1) * 2} value={(i + 1) * 2}>
                    {(i + 1) * 2} minutes
                  </option>
                ))}
              </Select>
            )}
          />
          <FormErrorMessage>{errors.gameTime?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.numberOfQuestions}>
          <FormLabel>Number of Questions</FormLabel>
          <Controller
            name="numberOfQuestions"
            control={control}
            defaultValue={3}
            render={({ field }) => (
              <Select {...field} placeholder="Select question count">
                 {questionCountOptions.map((count) => (
                  <option key={count} value={count}>
                    {count} questions
                  </option>
                ))}
              </Select>
            )}
          />
          <FormErrorMessage>
            {errors.numberOfQuestions?.message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      {/* Expiry Date and Expected Users */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        <FormControl isRequired isInvalid={!!errors.expiryDate}>
          <FormLabel>Expiry Date</FormLabel>
          <Input
            type="date"
            {...register("expiryDate")}
            min={new Date().toISOString().split("T")[0]}
          />
          <FormErrorMessage>{errors.expiryDate?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.expectedUsers}>
          <FormLabel>Expected Users</FormLabel>
          <Controller
            name="expectedUsers"
            control={control}
            defaultValue={1}
            render={({ field }) => (
              <NumberInput
                min={1}
                onChange={(value) => {
                  field.onChange(value);
                  handleExpectedUsersChange(value);
                }}
                value={field.value}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            )}
          />
          <FormHelperText
            color={creditsNeeded > (user?.credit ?? 0) ? "red.500" : "gray.600"}
          >
            You will be charged {creditsNeeded} credit{creditsNeeded > 1 ? "s" : ""}
          </FormHelperText>
          <FormErrorMessage>{errors.expectedUsers?.message}</FormErrorMessage>
        </FormControl>
      </SimpleGrid>

      {/* Credit Information */}
      <Alert
        status={creditsNeeded > (user?.credit ?? 0) ? "warning" : "info"}
        variant="subtle"
        borderRadius="md"
      >
        <AlertIcon />
        <Box>
          <AlertTitle>Credit Usage Information</AlertTitle>
          <AlertDescription>
            <Stack spacing={1}>
              <Text>• 1 credit is required per user</Text>
              <Text>• Total credits needed: {creditsNeeded}</Text>
              <Text>
                • Your current balance:{" "}
                {formatNumberWithCommas(user?.credit ?? 0)} credits
              </Text>
              {creditsNeeded > (user?.credit ?? 0) && (
                <Text color="orange.500">
                  You need {creditsNeeded - (user?.credit ?? 0)} more credits
                  to create this test
                </Text>
              )}
            </Stack>
          </AlertDescription>
        </Box>
      </Alert>

      <GameLevelExplanation isOpen={isOpen} onClose={onClose} />
      <Button
        mt={6}
        w="full"
        colorScheme="blue"
        type="submit"
        isDisabled={(user?.credit ?? 0) < creditsNeeded}
        isLoading={isSubmitting}
      >
        Save Memory IQ Test
      </Button>
    </Stack>
  );
};
