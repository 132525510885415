import {
  Box,
  Heading,
  Text,
  Container,
  VStack,
  HStack,
  Button,
  useColorModeValue,
  Icon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Link,
  Badge,
} from "@chakra-ui/react";
import { ApiReferenceMenu } from "../nav/ApiReferenceMenu";
import ContentCard from "../nav/ContentCard";
import { Highlighter } from "./Highlighter";
import { MotionElement } from "../../components/MotionElement";
import { FaNpm, FaGithub, FaPlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DocumentationSection } from "./DocumentationSection";

export const NPMDocumentation = () => {
  const navigate = useNavigate();
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const installationCode = {
    npm: "npm install the-last-spelling-bee-re-captcha",
    yarn: "yarn add the-last-spelling-bee-re-captcha"
  };



  return (
    <ContentCard>
      <ApiReferenceMenu />
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8} align="stretch">
          {/* Header Section */}
          <MotionElement type="fadeInY">
            <Box 
              bg={bgColor} 
              p={8} 
              rounded="xl" 
              shadow="md" 
              borderWidth={1}
              borderColor={borderColor}
            >
              <VStack spacing={4} align="start">
                <HStack spacing={4} align="center">
                  <Icon as={FaNpm} w={10} h={10} color="red.500" />
                  <Heading size="lg">the-last-spelling-bee-re-captcha</Heading>
                  <Badge colorScheme="blue" fontSize="md">v1.0.0</Badge>
                </HStack>
                <Text fontSize="lg" color="gray.600">
                  A modern and simple framework to generate and verify TheLastSpellingBee ReCaptcha
                </Text>
                <HStack spacing={4}>
                  <Link 
                    href="https://www.npmjs.com/package/the-last-spelling-bee-re-captcha" 
                    isExternal
                  >
                    <Button
                      leftIcon={<FaNpm />}
                      colorScheme="red"
                      variant="solid"
                    >
                      NPM Package
                    </Button>
                  </Link>
                  <Link 
                    href="https://github.com/suleigolden/thelastspellingbee-npm-package" 
                    isExternal
                  >
                    <Button
                      leftIcon={<FaGithub />}
                      colorScheme="gray"
                      variant="outline"
                    >
                      GitHub
                    </Button>
                  </Link>
                  <Button
                    leftIcon={<FaPlay />}
                    colorScheme="blue"
                    onClick={() => navigate("/contact-us")}
                  >
                    Live Demo
                  </Button>
                </HStack>
              </VStack>
            </Box>
          </MotionElement>

          {/* Installation Section */}
          <MotionElement type="fadeInY">
            <Box bg={bgColor} p={8} rounded="xl" shadow="md">
              <Heading size="md" mb={4}>Installation</Heading>
              <Tabs variant="enclosed" colorScheme="blue">
                <TabList>
                  <Tab>npm</Tab>
                  <Tab>yarn</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel p={0} pt={4}>
                    <Highlighter content={installationCode.npm} />
                  </TabPanel>
                  <TabPanel p={0} pt={4}>
                    <Highlighter content={installationCode.yarn} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </MotionElement>
          <DocumentationSection />
        </VStack>
      </Container>
    </ContentCard>
  );
};