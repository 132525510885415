import { useState, useEffect, useCallback } from "react";
import {
  Box,
  FormControl,
  Input,
  Stack,
  Button,
  Text,
  Flex,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { QuestionsCard } from "./question";
import GameHeaderCard from "./GameHeaderCard";
import { validateNumberQuestion, validateQuestion } from "./helper";
import { useGameComplete } from "../../../hooks/useGameComplete";
import { AnimatedBackground } from "./result-chart/AnimatedBackground";
import { useAnswerFeedback } from "../../../hooks/useAnswerFeedback";
import ContentCard from "../../nav/ContentCard";
import { backgroundImages } from "./BgImages";
import { Background } from "../../../common/background/Background";
import { useSocket } from "../../../hooks/use-socket";
import { useGame } from "../../../hooks/use-game";
import { useAppDispatch } from "../../../redux-action/hooks/useTypedSelector";
import { api } from "../../../redux-action/api.service";
import { setCurrentScore } from "../../../redux-action/slices/game-slice";
import {
  setCurrentQuestionIndex,
  selectCurrentQuestionIndex,
} from "../../../redux-action/slices/question-number-slice";
import { useSelector } from "react-redux";
import { LoadingScoreCalculation } from "./LoadingScoreCalculation";
import { VStack } from "@chakra-ui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useGameTimer } from "../../../hooks/useGameTimer";

export const SpellingGamePlay = () => {
  const { instruction, questions, currentScore } = useGame();
  const currentQuestionIndex = useSelector(selectCurrentQuestionIndex);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [gameScore, setGameScore] = useState<number>(currentScore);
  const [answerAlert, setAnswerAlert] = useState<any>();
  const { gameComplete } = useGameComplete();
  const { renderAnswer } = useAnswerFeedback();
  const { playersScores, updateScore } = useSocket();
  const { handleGameComplete } = useGameTimer();
  const [isHideLivePlayersScore, setIsHideLivePlayersScore] =
    useState<boolean>(false);

  const [bgImage] = useState(
    () => backgroundImages[Math.floor(Math.random() * backgroundImages.length)],
  );

  const [clickSnd] = useState(new Audio("/sounds/click.wav"));

  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });

  const dispatch = useAppDispatch();
  const toast = useToast();

  const onSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    clickSnd.play();
    try {
      const currentQuestion = questions[currentQuestionIndex];

      const answerData = {
        ...formData,
        question: validateQuestion(JSON.stringify(currentQuestion.question)),
        type: currentQuestion.type,
        hiddenValue: currentQuestion.hiddenValue ?? null,
      };
      const response = await api
        .service("game-engine/check-answer")
        .create(answerData);

      // First update the score in game state
      const newScore = response.score + currentScore;
      await dispatch(setCurrentScore(newScore));
      setGameScore(newScore);

      // Then update socket with new score
      updateScore(newScore); // Pass the new score

      dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1));

      setAnswerAlert(renderAnswer(response.score, response.message));

      setShowFeedback(true);
      setTimeout(() => {
        setShowFeedback(false);
      }, 2000);
      setFormData({
        ...formData,
        answer: "",
      });
    } catch (error: any) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    checkGameComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex, gameScore]);

  const checkGameComplete = async () => {
    if (currentQuestionIndex >= questions?.length) {
      await gameComplete("formattedTime");
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value.toUpperCase(),
    });
  };

  const onGameOver = useCallback(async () => {
    await handleGameComplete();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameComplete]);

  const renderPlayerScores = () =>
    Object.entries(playersScores).map(([id, data]) => (
      <Box
        key={id}
        p={2}
        color="#FFF"
        borderRadius="md"
        bg="rgba(0, 0, 0, 0.5)"
        mt={2}
      >
        <Text textAlign={"left"} textTransform={"capitalize"}>
          {data.name} : <strong> {data.score}</strong>
        </Text>
      </Box>
    ));

  if (currentQuestionIndex >= questions?.length) {
    return <LoadingScoreCalculation />;
  }

  return (
    <ContentCard
      position="relative"
      bgImage={`url('${bgImage}')`}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      width={"100%"}
      borderRadius={0}
      bg={"transparent"}
      p={0}
    >
      <AnimatedBackground />
      <Background />

      <GameHeaderCard
        currentQuestionIndex={currentQuestionIndex}
        totalQuestions={questions?.length}
        score={gameScore}
        onGameOver={onGameOver}
      />

      <Flex
        align="center"
        justify="center"
        width="100%"
        direction={{ base: "column", md: "row" }}
        gap={8}
        p={6}
        // minH="calc(100vh - 200px)"
      >
        <Stack
          width={{ base: "100%", md: "65%" }}
          spacing={6}
          p={4}
          borderRadius="xl"
          bg="rgba(255, 255, 255, 0.3)"
          boxShadow="xl"
          zIndex={1000}
        >
          {currentQuestionIndex < questions?.length && (
            <Box flex={1}>
              <QuestionsCard
                defaultInstruction={instruction}
                instruction={questions[currentQuestionIndex].instructions}
                question={validateNumberQuestion(
                  questions[currentQuestionIndex].question,
                )}
              />
            </Box>
          )}
          <Box as="form" mt={3} onSubmit={onSubmit}>
            <FormControl id="Answer" w="full">
              <Input
                type="text"
                id="answer"
                onChange={onChange}
                placeholder="Enter your answer here..."
                value={formData.answer}
                fontSize="2xl"
                h={16}
                padding={2}
                bg="#FFF"
                borderWidth={2}
                borderColor="blue.200"
                _focus={{
                  borderColor: "blue.400",
                  boxShadow: "0 0 0 1px blue.400",
                }}
                _hover={{
                  borderColor: "blue.300",
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  toast({
                    title: "Warning",
                    description: "Please type the answer manually",
                    status: "warning",
                    duration: 2000,
                  });
                }}
                autoComplete="off"
                spellCheck="false"
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme="blue"
              w="full"
              size="lg"
              mt={4}
              h={14}
              fontSize="lg"
              _hover={{
                transform: "translateY(-2px)",
                boxShadow: "lg",
              }}
              transition="all 0.2s"
            >
              Submit Answer
            </Button>
            <Text textAlign="center" mt={3}>
              {showFeedback && answerAlert}
            </Text>
          </Box>
        </Stack>

        {/* Right Column - Live Scores */}
        <Box
          width={{ base: "100%", md: "25%" }}
          height="fit-content"
          minH="calc(50vh - 50px)"
        >
          <Box
            borderRadius="xl"
            p={4}
            boxShadow="lg"
            bg="rgba(255, 255, 255, 0.9)"
          >
            <VStack spacing={4} align="stretch">
              <Flex justify="space-between" align="center">
                <Heading color="blue.700" fontSize="xl">
                  Live Player Scores
                </Heading>
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="blue"
                  onClick={() =>
                    setIsHideLivePlayersScore(!isHideLivePlayersScore)
                  }
                  leftIcon={isHideLivePlayersScore ? <FaEyeSlash /> : <FaEye />}
                >
                  {isHideLivePlayersScore ? "Show" : "Hide"}
                </Button>
              </Flex>
            </VStack>
            {!isHideLivePlayersScore && renderPlayerScores()}
          </Box>
        </Box>
      </Flex>
    </ContentCard>
  );
};
