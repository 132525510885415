import { createContext, useContext, ReactNode, useState, useCallback } from 'react';
import { Recaptcha } from '../types/re-captcha-type';
import { useApiService } from '../redux-action/api.service';

type ReCaptchaContextType = {
  reCaptchas: Recaptcha[];
  isLoading: boolean;
  fetchReCaptchas: (userId: string) => Promise<void>;
  refreshReCaptchas: () => Promise<void>;
}

const ReCaptchaContext = createContext<ReCaptchaContextType | undefined>(undefined);

export const ReCaptchaProvider = ({ children }: { children: ReactNode }) => {
  const [reCaptchas, setReCaptchas] = useState<Recaptcha[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userId, setUserId] = useState<string>('');
  const api = useApiService();

  const fetchReCaptchas = useCallback(async (newUserId: string) => {
    try {
      setIsLoading(true);
      setUserId(newUserId);
      const response = await api.service(`re-captcha/user/${newUserId}`).find();
      setReCaptchas(response.sort((a: Recaptcha, b: Recaptcha) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
    } catch (error) {
      console.error("Failed to fetch reCaptchas:", error);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const refreshReCaptchas = useCallback(async () => {
    if (userId) {
      await fetchReCaptchas(userId);
    }
  }, [userId, fetchReCaptchas]);

  return (
    <ReCaptchaContext.Provider value={{ reCaptchas, isLoading, fetchReCaptchas, refreshReCaptchas }}>
      {children}
    </ReCaptchaContext.Provider>
  );
};

export const useReCaptcha = () => {
  const context = useContext(ReCaptchaContext);
  if (!context) {
    throw new Error('useReCaptcha must be used within ReCaptchaProvider');
  }
  return context;
}; 