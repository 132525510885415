import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Link,
  Spinner,
  Stack,
  Text,
  VStack,
  HStack,
  Badge,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { PlayerDetailsModal, PlayerResultProps } from "./PlayerDetailsModal";
import { addSpaceAfterEachWord } from "../../../../common/utils/helperFuntions";
import ContentCard from "../../../nav/ContentCard";
import { Score } from "../../../../types/score-types";
import { FaArrowLeft, FaTrophy, FaMedal, FaAward } from "react-icons/fa";
import { dateToString } from "../../../../common/utils/date-time";
import { useApiService } from "../../../../redux-action/api.service";

type TeamBondingResultProps = {
  score: Score;
  isBackBtn?: boolean;
  isAuthView?: boolean;
};

// Add custom tooltip component
const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box 
        bg="white" 
        p={2} 
        borderRadius="md" 
        boxShadow="lg" 
        border="1px"
        borderColor="gray.200"
      >
        <Text fontWeight="bold" color="gray.700">
          M-IQ: {payload[0].value}%
        </Text>
      </Box>
    );
  }
  return null;
};

export const TeamBondingResult: FC<TeamBondingResultProps> = ({
  score,
  isBackBtn = false,
  isAuthView = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerResultProps>();
  const api = useApiService();
  const [scores, setScores] = useState<Score[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  useEffect(() => {
    const fetchScores = async () => {
      const response = await api.service(`game-scores/game/${score.gameId}`).find();
      setScores(response);
    };

    if (score) {
      try {
        setIsLoading(true);
        fetchScores();
      } catch (err) {
        console.log(err);
        setError(err instanceof Error ? err : new Error('Failed to fetch score.'));
      } finally {
        setIsLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score]);

  const handleChartClick = (event: any) => {
    if (event.activePayload && event.activePayload[0]) {
      const player = event.activePayload[0].payload;
      setSelectedPlayer(player as PlayerResultProps);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const data = scores.map((score) => {
    const totalMarks = score?.game?.numberOfQuestions ?? 0 * 5;
    const percentage = ((score.score / (totalMarks * 5)) * 100).toFixed(2);
    const correctAnswers = score.score / 5;
    const incorrectAnswers =
      (score?.game?.numberOfQuestions ?? 0) - correctAnswers;

    return {
      name: `${score.user?.firstName} ${score.user?.lastName}`,
      value: percentage,
      correct: correctAnswers.toFixed(0),
      incorrect: incorrectAnswers.toFixed(0),
      score: score,
    };
  });

  const getRankIcon = (index: number) => {
    switch(index) {
      case 0: return <FaTrophy color="gold" />;
      case 1: return <FaMedal color="silver" />;
      case 2: return <FaAward color="bronze" />;
      default: return null;
    }
  };

  if (isLoading || !scores) {
    return <Spinner />;
  }
  if (error) {
    return <Text color="red.500">Error loading game results: {error.message}</Text>;
  }
  return (
    <ContentCard bg={"transparent"}>
      <Stack spacing={6} mx="auto" py={8} px={6} width={{ base: "full", md: 990 }}>
        <Box
          bg={bgColor}
          borderRadius="xl"
          boxShadow="xl"
          overflow="hidden"
          border="1px"
          borderColor={borderColor}
        >
          {/* Header Section */}
          <VStack spacing={4} p={6} bg="blue.50">
            {isBackBtn && (
              <Box alignSelf="start">
                <Button
                  leftIcon={<FaArrowLeft />}
                  as={Link}
                  href={`/auth/game-result/${score.gameId}`}
                  size="sm"
                  colorScheme="blue"
                  variant="ghost"
                >
                  Back to Results
                </Button>
              </Box>
            )}
            
            <Heading size="lg" color="blue.600">
              Team Performance Results
            </Heading>
            
            <HStack spacing={4}>
              <Badge colorScheme="orange" p={2} borderRadius="md">
                {addSpaceAfterEachWord(score?.game?.gameCategory ?? "")}
              </Badge>
              <Badge colorScheme="green" p={2} borderRadius="md">
                {score?.game?.name}
              </Badge>
            </HStack>
          </VStack>

          {/* Chart Section */}
          <Box p={6}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                data={data}
                layout="vertical"
                margin={{ top: 20, right: 50, left: 0, bottom: 20 }}
                onClick={handleChartClick}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis type="category" width={100} dataKey="name" />
                <YAxis 
                  type="category" 
                  width={150} 
                  dataKey="name"
                  tick={({ y, payload }) => (
                    <g transform={`translate(0,${y})`}>
                      <text x="-5" y="0" dy="0.35em" textAnchor="end">
                        {getRankIcon(data.findIndex(d => d.name === payload.value))}
                        <tspan x="-25">{payload.value}</tspan>
                      </text>
                    </g>
                  )}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="value"
                  fill="#4299E1"
                  radius={[0, 4, 4, 0]}
                  barSize={20}
                  label={{
                    position: "right",
                    formatter: (value: number) => `${value}%`,
                    fill: "#2D3748",
                    fontWeight: "bold"
                  }}
                  cursor="pointer"
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>

          {/* Footer Section */}
          <Box p={6} bg="gray.50" borderTop="1px" borderColor={borderColor}>
            <VStack spacing={2} align="stretch">
              <Text color="gray.600">
                Test Date: {dateToString(score?.createdAt ?? '')}
              </Text>
              <Text color="gray.600">
                Total Participants: {scores.length}
              </Text>
              {!isAuthView && (
                <Text color="blue.600" fontWeight="bold" textAlign="center" mt={4}>
                  Awesome teamwork! Your team did great.
                </Text>
              )}
              <Text fontSize="sm" textAlign="center" color="red.500" mt={2}>
                Click any bar to view detailed certificate
              </Text>
            </VStack>
          </Box>
        </Box>
      </Stack>

      <PlayerDetailsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        player={selectedPlayer}
      />
    </ContentCard>
  );
};