import {
  calculateIQScore,
  clearStorage,
} from "../apps/spelling/game-play/helper";
import { useApiService } from "../redux-action/api.service";
import { useAppDispatch } from "../redux-action/hooks/useTypedSelector";
import { setResultData } from "../redux-action/slices/game-result-slice";
import { resetGame } from "../redux-action/slices/game-slice";
import { resetQuestionNumber } from "../redux-action/slices/question-number-slice";
import { useGame } from "./use-game";

const formatTimeTaken = (timeCompleted: string, gameTimeInMinutes: number) => {
  // Parse the timeCompleted (format: "0:00:27")
  const [hours, minutes, seconds] = timeCompleted.split(':').map(Number);
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;

  const formatTime = (secs: number) => {
    if (secs < 60) return `${secs} seconds`;
    if (secs < 3600) {
      const mins = Math.floor(secs / 60);
      const remainingSecs = secs % 60;
      return `${mins} minute${mins > 1 ? 's' : ''}${remainingSecs > 0 ? ` ${remainingSecs} seconds` : ''}`;
    }
    return timeCompleted; 
  };

  return `${formatTime(totalSeconds)} of ${gameTimeInMinutes} minutes`;
};

export const useGameComplete = () => {
  const { gameScore, currentScore, questions, game } = useGame();
  const api = useApiService();
  const dispatch = useAppDispatch();

  const gameComplete = async (endGameTime: string) => {
    const memoryIQScore = Number(
      calculateIQScore(questions?.length ?? 0, currentScore),
    );
    
    const timeTaken = formatTimeTaken(endGameTime, game?.gameTime ?? 0);
    const finishGameData = {
      score: currentScore,
      memoryIQScore: memoryIQScore ?? 0,
      timeCompleted: timeTaken,
      ageGroup: gameScore?.ageGroup ?? "",
    };
    const response = await api
      .service("game-scores")
      .patch(gameScore?.id ?? "", finishGameData);
    console.log(gameScore);
    await api.service('email/score').create({
      firstName: gameScore?.firstName ?? "",
      gameName: game?.name ?? "",
      score: currentScore,
      email: gameScore?.email ?? "",
      gameScoreId: gameScore?.id ?? "",
    });

    await dispatch(setResultData(response));
    await dispatch(resetGame());
    await dispatch(resetQuestionNumber());
    clearStorage();
    window.location.href = "/spelling/result";
  };

  return { gameComplete };
};
