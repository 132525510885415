import * as yup from 'yup';
import { InferType } from 'yup';

export const UserSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
});

export const PasswordSchema = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup
    .string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
      'Password must contain at least one uppercase letter, one lowercase letter, and one number'
    ),
  confirmPassword: yup
    .string()
    .required('Please confirm your password')
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UserSchema = InferType<typeof UserSchema>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PasswordSchema = InferType<typeof PasswordSchema>;

