import {
  Box,
  Button,
  Container,
  Stack,
  Heading,
  Text,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterSchema } from "./schema";
import { FormInput } from "../../components/form/FormInput";
import { useAuth } from '../../hooks/useAuth';
import { GoogleSigInButton } from "./GoogleSigInButton";


export const SignUp = () => {
  const { handleSignUp } = useAuth();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<RegisterSchema>({
    resolver: yupResolver(RegisterSchema),
  });

  const onSubmit = async (data: RegisterSchema) => {
    try {

     await handleSignUp(data)
    } catch (err) {
      console.error("Registration failed:", err);
    }
  };

  return (
    <Box
      bg={"#dbeafe"}
      minH="100vh"
      py={{ base: '12', md: '24' }}
    >
      <Container maxW="lg" px={{ base: '0', sm: '8' }}>
        <Stack spacing="8">
          <Stack spacing="6" align="center">
          <Heading
              fontSize={{ base: "24px", md: "32px" }}
              mb={5}
              textAlign={"center"}
            >
              Create Your Account
            </Heading>
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={"#FFF"}
            boxShadow={{ base: "none", sm: "md" }}
            borderRadius={{ base: "none", sm: "xl" }}
          >
            <Box as="form" onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="6">
                  <FormInput
                    label="First Name"
                    name="firstName"
                    register={register}
                    error={errors.firstName}
                  />
                  <FormInput
                    label="Last Name"
                    name="lastName"
                    register={register}
                    error={errors.lastName}
                  />
                <FormInput
                  label="Email"
                  name="email"
                  type="email"
                  register={register}
                  error={errors.email}
                />
                <FormInput
                  label="Password"
                  name="password"
                  type="password"
                  register={register}
                  error={errors.password}
                />
                <FormInput
                  label="Confirm Password"
                  name="confirmPassword"
                  type="password"
                  register={register}
                  error={errors.confirmPassword}
                />
                <Button
                  type="submit"
                  colorScheme="blue"
                  size="lg"
                  fontSize="md"
                  isLoading={isSubmitting}
                >
                  Register
                </Button>
                <GoogleSigInButton />
              </Stack>
            </Box>
            <Stack pt="6" spacing="1" align="center">
              <Text>
                Already have an account?{" "}
                <Link as={RouterLink} to="/auth/login" color="blue.500">
                  Login
                </Link>
              </Text>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
