import React, { memo } from "react";
import {
  Box,
  Text,
  CircularProgress,
  CircularProgressLabel,
  HStack,
  Icon,
} from "@chakra-ui/react";
import { FaClock } from "react-icons/fa";
import { LoadingScoreCalculation } from "./LoadingScoreCalculation";
import { useGameTimer, formatTime } from "../../../hooks/useGameTimer";



const GameTime: React.FC        = () => {
  const { timeRemaining, progress, isLowTime, isTimerLoading, game } = useGameTimer();

  if (!game) {
    return <CircularProgress isIndeterminate color="blue.500" size="40px" />;
  }

  if (isTimerLoading) {
    return <LoadingScoreCalculation />;
  }

  return (
    <HStack
      spacing={4}
      bg={isLowTime ? "red.50" : "white"}
      p={1}
      borderRadius="md"
      shadow="sm"
      transition="all 0.2s"
      animation={isLowTime ? "pulse 1.5s infinite" : "none"}
      w={"170px"}
    >
      <CircularProgress
        value={progress}
        color={isLowTime ? "red.400" : "blue.400"}
        thickness="12px"
        size="40px"
      >
        <CircularProgressLabel>
          <Icon
            as={FaClock}
            color={isLowTime ? "red.500" : "blue.500"}
            w={5}
            h={5}
          />
        </CircularProgressLabel>
      </CircularProgress>
      <Box>
        <Text
          fontSize="lg"
          fontWeight="bold"
          color={isLowTime ? "red.600" : "blue.600"}
        >
          {formatTime(timeRemaining || 0)}
        </Text>
      </Box>
    </HStack>
  );
};

export default memo(GameTime);

// Add this CSS animation
const style = document.createElement("style");
style.textContent = `
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.02); }
    100% { transform: scale(1); }
  }
`;
document.head.appendChild(style);