import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
  Box,
  VStack,
  Icon,
  List,
  ListItem,
  ListIcon,
  useColorModeValue,
  Heading,
  ExpandedIndex,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiInfo } from "react-icons/fi";
import { FaCheckCircle, FaLightbulb, FaBrain, FaCalculator } from "react-icons/fa";
import { useState } from "react";

type InstructionsProps = {
  defaultOpen?: boolean;
}

const instructions = [
  {
    title: "Basic Rules",
    content: "Letters (A-Z) are assigned numbers (1-26)",
    icon: FaLightbulb,
  },
  {
    title: "Letter to Number",
    content: "A=1, B=2, C=3... Z=26",
    icon: FaCalculator,
  },
  {
    title: "Number to Letter",
    content: "1=A, 2=B, 3=C... 26=Z",
    icon: FaBrain,
  },
  {
    title: "Example",
    content: "DAD = 4-1-4",
    icon: FaCheckCircle,
  },
];

export const Instructions = ({ defaultOpen = false }: InstructionsProps) => {
  const bgColor = useColorModeValue("blue.50", "blue.900");
  const borderColor = useColorModeValue("blue.100", "blue.700");
  const textColor = useColorModeValue("gray.600", "gray.200");
  const headingColor = useColorModeValue("blue.600", "blue.200");

  const [defaultIndex] = useState<ExpandedIndex | undefined>(defaultOpen ? 0 : undefined);

  return (
    <Accordion 
      allowToggle
      defaultIndex={defaultIndex}
      width="100%" 
      borderRadius="lg" 
      overflow="hidden"
      borderWidth="1px"
      borderColor={borderColor}
    >
      <AccordionItem border="none">
        {({ isExpanded }) => (
          <>
            <AccordionButton
              py={4}
              bg={bgColor}
              _hover={{ bg: 'blue.100' }}
              transition="all 0.2s"
            >
              <Box flex="1" textAlign="left">
                <Box display="flex" alignItems="center">
                  <Icon as={FiInfo} color="blue.500" boxSize={5} />
                  <Heading size="sm" ml={3} color={headingColor}>
                    How to Play - Memory IQ Test Instructions
                  </Heading>
                </Box>
              </Box>
              <ChevronDownIcon
                fontSize="24px"
                transform={isExpanded ? "rotate(-180deg)" : undefined}
                transition="transform 0.2s"
                color="blue.500"
              />
            </AccordionButton>
            <AccordionPanel 
              pb={6} 
              pt={4}
              bg={bgColor}
            >
              <VStack align="stretch" spacing={4}>
                <Text color={textColor} fontSize="md" mb={4}>
                  Welcome to the Memory IQ Test! Here's how the letter-number conversion works:
                </Text>
                
                <List spacing={4}>
                  {instructions.map((instruction, index) => (
                    <ListItem
                      key={index}
                      display="flex"
                      alignItems="center"
                      p={3}
                      bg="white"
                      borderRadius="md"
                      boxShadow="sm"
                      transition="all 0.2s"
                      _hover={{
                        transform: "translateX(2px)",
                        boxShadow: "md",
                      }}
                    >
                      <ListIcon 
                        as={instruction.icon} 
                        color="blue.500" 
                        fontSize="20px"
                        mr={3}
                      />
                      <Box>
                        <Text fontWeight="medium" color="blue.700">
                          {instruction.title}
                        </Text>
                        <Text color={textColor} fontSize="sm">
                          {instruction.content}
                        </Text>
                      </Box>
                    </ListItem>
                  ))}
                </List>

                <Box 
                  mt={4} 
                  p={4} 
                  bg="blue.100" 
                  borderRadius="md"
                  borderLeft="4px solid"
                  borderLeftColor="blue.500"
                >
                  <Text color="blue.700" fontSize="sm" fontWeight="medium">
                    💡 Pro Tip: Take your time to understand the pattern. The key is to think about 
                    both letter-to-number and number-to-letter conversions.
                  </Text>
                </Box>
              </VStack>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
