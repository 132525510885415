import {
    chakra,
    forwardRef,
    useColorModeValue,
    Center,
    Flex,
    BoxProps,
  } from "@chakra-ui/react";
  import { CustomCardProps } from "../../theme/theme";
  
  interface DefaultContentCardProps extends BoxProps {
    minHeight?: string | number;
    marginTop?: string | number;
    contentWidth?: { [key: string]: string | number };
  }
  const DefaultContentCard = forwardRef<CustomCardProps & DefaultContentCardProps, "div">(
    ({ minHeight, marginTop, contentWidth, children, ...rest }, ref) => {
      const bgColor = useColorModeValue("blue.50", "gray.800");
  
      return (
        <chakra.div
          ref={ref}
          bg={bgColor}
          p={{ base: 5, md: 5, lg: 5, xl: 8, "2xl": 8 }}
          rounded={"xl"}
          align={"center"}
          pos={"relative"}
          {...rest}
        >
          <Center
            // pl={{
            //   base: 1,
            //   md: 1,
            //   lg: 1,
            //   xl: 190,
            //   "2xl": 90,
            // }}
          >
            <Flex
              minH={minHeight || "100vh"}
              mt={marginTop || 90}
              direction="column"
              // width={
              //   contentWidth || {
              //     base: "100%",
              //     md: "100%",
              //     lg: "100%",
              //     xl: "97%",
              //     "2xl": "80%",
              //   }
              // }
            >
              {children}
            </Flex>
          </Center>
        </chakra.div>
      );
    }
  );
  
  export default DefaultContentCard;