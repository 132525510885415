import { FC, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import anime from "animejs";


const animate = (buttonId: string, delay: number) => {
	anime({
		targets: [`#${buttonId}`],
		scale: [0, 1],
		duration: 300,
		easing: 'easeOutBack',
		delay,
	})
}
    
type QuestionsProps = {
  question: string;
  index: number;
};

export const Question: FC<QuestionsProps> = ({ question, index }) => {
    const buttonId = `character-option-${index}`

	useEffect(() => {
		animate(buttonId, 300 * index)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

  return (
      <Button
        id={buttonId}
        size="lg"
        colorScheme="blue"
        variant="outline"
        fontSize={["2xl", "3xl"]}
        borderRadius={3}
        padding={"19px 3px 19px 3px"}
        mr={2}
        border={"1px #F26B3A solid"}
        cursor={"default"}
      >
        {question}
      </Button>
  );
};
