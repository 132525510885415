import { FC } from "react";
import {
  Flex,
  Text,
  VStack,
  Button,
  Icon,
} from "@chakra-ui/react";
import ReactCountryFlag from "react-country-flag";
import { Score } from "../../../types/score-types";
import { FaCrown } from "react-icons/fa";

type ScoreCardProps = {
  score: Score;
  onViewCertificate: (score: Score) => void;
  showCertificateButton?: boolean;
}

export const ScoreCard: FC<ScoreCardProps> = ({ score, onViewCertificate, showCertificateButton = true }) => {
  const showCrown = score.memoryIQScore >= 89;

  return (
    <Flex
      align="center"
      justify="space-between"
      bg="white"
      borderRadius="md"
      boxShadow="lg"
      p={3}
      _hover={{ transform: "translateY(-2px)", transition: "all 0.2s" }}
    >
      <Flex align="center">
        <Text mr={3} fontSize="2xl">
          <ReactCountryFlag countryCode={score.country ?? "CA"} />
        </Text>
        <VStack align="start" spacing={0}>
          <Text fontWeight="medium">
            {score.user?.firstName} {score.user?.lastName}
          </Text>
          <Text color="gray.500" fontSize="sm">
            {score.timeCompleted}
          </Text>
        </VStack>
      </Flex>

      <Flex fontWeight="bold" fontSize="lg" color="blue.600" align="center">
        <Text
          as="span"
          mr={showCrown ? 2 : 0}
        >
          MIQ - {score.memoryIQScore}{" "}
        </Text>
        {showCrown && (
          <Icon 
            as={FaCrown} 
            color="yellow.400" 
            w={5} 
            h={5} 
            mt={-3}
            transform="translateY(-8px)"
          />
        )}
      </Flex>

      {showCertificateButton && (
        <Button
          onClick={() => onViewCertificate(score)}
          colorScheme="blue"
          variant="outline"
          size="sm"
          ml={3}
        >
          View Certificate
        </Button>
      )}
    </Flex>
  );
}; 