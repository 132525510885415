import React, { useState } from "react";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Center,
  Text
} from "@chakra-ui/react";
import { useGame } from "../../../hooks/use-game";
import { LoadingScoreCalculation } from "./LoadingScoreCalculation";
import { useGameTimer } from "../../../hooks/useGameTimer";

export const EndGameModal = () => {
  const {game} = useGame();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const [isEndGameLoading, setIsEndGameLoading] = useState<boolean>(false);
  const { handleGameComplete } = useGameTimer();
  
  const onSubmit = async () => {
    
    try {
     
      setIsEndGameLoading(true);
      await handleGameComplete();
      onClose();
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setIsEndGameLoading(false);
    }
  };
 
  if (isEndGameLoading) {
    return <LoadingScoreCalculation />;
  }

  return (
    <>
      <Button
        size="sm"
        borderRadius={"md"}
        colorScheme="blue"
        variant="outline"
        bg={"#FFF"}
        ml={1}
        onClick={onOpen}
      >
        End Game
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent p={3}>
          <ModalHeader>End Game</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>Are you sure you want to end this game 
            <Text as={"span"} fontWeight={"bold"}> {game?.name}</Text>?
          </ModalBody>
          <ModalFooter>
            <Center>
              <Button colorScheme={"blue"} mr={3} onClick={onSubmit}>
                Yes
              </Button>
              <Button variant="outline" colorScheme={"blue"} onClick={onClose}>
                No
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
