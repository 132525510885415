import { Flex, Stack } from "@chakra-ui/react";
import { EducationResult } from "./result-chart/EducationResult";
import { useAppSelector } from "../../../redux-action/hooks/useTypedSelector";
import { RootState } from "../../../redux-action/store";
import { Background } from "../../../common/background/Background";
  

export const SpellingResult = () => {
  const {score} = useAppSelector((state: RootState) => state.gameResult);
 
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"}  bg={"transparent"}>
      <Background />
      <Stack spacing={8} mx={"auto"} width={"full"} py={12} px={6}>
        {/* {score?.game?.gameCategory === "TeamBonding" ? (
          <TeamBondingResult score={score} />
        ) : (
          <EducationResult score={score} />
        )} */}
         <EducationResult score={score} />
      </Stack>
    </Flex>
  );
};
