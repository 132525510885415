import { useApiService } from "../redux-action/api.service";
import { useState, useEffect } from "react";
import { Score } from "../types/score-types";

export const useLatestResults = (itemsPerPage: number = 10) => {
  const api = useApiService();
  const [scores, setScores] = useState<Score[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchScores = async () => {
      const response = await api.service(`game-scores/public`).find();
      setScores(response);
    };

    try {
      setIsLoading(true);
      fetchScores();
    } catch (err) {
      console.log(err);
      setError(
        err instanceof Error ? err : new Error("Failed to fetch score."),
      );
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = scores.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return {
    isLoading,
    paginate,
    currentItems,
    scores,
    itemsPerPage,
    error
  };
};
