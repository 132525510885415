import { FC, useState, useEffect, memo } from "react";
import {
  SimpleGrid,
  Box,
  Text,
  Flex,
  Button,
  Spinner,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { GamePlayInstruction } from "./GamePlayInstruction";
import GameTime from "./GameTime";
import "./animation.css";
import { EndGameModal } from "./EndGameModal";
import { useGame } from "../../../hooks/use-game";
import { getLevel } from "./helper";

type GameHeaderCardProps = {
  currentQuestionIndex: number;
  totalQuestions: number;
  score?: number;
  onGameOver?: (gameCompleteTime?: string) => void;
};

const GameHeaderCard: FC<GameHeaderCardProps> = ({
  currentQuestionIndex,
  totalQuestions,
  score,
  onGameOver,
}) => {
  const { user, game, currentScore } = useGame();
  const [isFlashing, setIsFlashing] = useState<boolean>(false);
  const [prevScore, setPrevScore] = useState<number>(score ?? 0);
  const levelName = getLevel(game?.questionType ?? "");


  // Trigger flash animation when the score changes
  useEffect(() => {
    if (score !== prevScore) {
      setIsFlashing(true);
      setTimeout(() => setIsFlashing(false), 500); // Flash duration
      setPrevScore(score ?? 0);
    }
  }, [score, prevScore]);

  if (!game) {
    return <Spinner />;
  }
  return (
    <VStack
      p={6}
      w="full"
      spacing={6}
      bg="rgba(0, 0, 0, 0.3)"
      borderRadius={"xl"}
      boxShadow="lg"
    >
      <Box textAlign="center">
        <Flex
          justify="space-between"
          align="center"
          px={4}
          fontSize="md"
          fontWeight="bold"
          color="#FFF"
        >
          <HStack spacing={4}>
            <Text>👋 Hi, {user?.firstName || "Player"}</Text>
            <Text>|</Text>
            <Text>{game?.name || "Unknown"}</Text>
            <Text>|</Text>
            <Text>
              <Text as="span" color="yellow.300" fontWeight="bold" fontSize={"md"}>
                {levelName}
              </Text>
            </Text>
          </HStack>
          <EndGameModal />
        </Flex>
      </Box>

      {/* Game Details */}
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={8}
        width="full"
        maxW="4xl"
        mx="auto"
      >
        <GamePlayInstruction />
        <GameTime  />
        <Button
          size="lg"
          cursor={"default"}
          bg="rgba(255, 255, 255, 0.9)"
          color="blue.600"
          w={200}
          h={16}
          boxShadow="lg"
          className={isFlashing ? "flash" : ""}
        >
          <VStack spacing={1}>
            <Text fontSize="sm" color="gray.600">
              Current Score
            </Text>
            <Text fontSize="2xl" fontWeight="bold">
              {currentScore > 0 ? currentScore : 0}
            </Text>
          </VStack>
        </Button>
      </SimpleGrid>

      {/* Progress Indicator */}
      <HStack
        spacing={4}
        justify="center"
        bg="rgba(255, 255, 255, 0.1)"
        p={3}
        borderRadius="full"
      >
        <Text fontSize="lg" fontWeight="bold" color={"#FFF"}>
          Question
          <Text
            as={"strong"}
            bg="blue.500"
            color="white"
            borderRadius={"full"}
            px={4}
            py={2}
            mr={2}
            ml={2}
          >
            {currentQuestionIndex + 1}
          </Text>
          of
          <Text
            as={"strong"}
            bg="blue.500"
            color="white"
            borderRadius={"full"}
            px={4}
            py={2}
            ml={2}
          >
            {totalQuestions}
          </Text>
        </Text>
      </HStack>
    </VStack>
  );
};

export default memo(GameHeaderCard);
