import {
  Box,
  Heading,
  Text,
  Stack,
  Container,
  SimpleGrid,
  Button,
  Icon,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { MotionElement } from "../../components/MotionElement";
import { FaShieldAlt, FaRobot, FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

type FeatureProps = {
  title: string;
  text: string;
  icon: React.ElementType;
};

const Feature = ({ title, text, icon }: FeatureProps) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const shadowColor = useColorModeValue("gray.100", "gray.700");

  return (
    <MotionElement type="fadeInY">
      <Stack
        bg={bgColor}
        boxShadow={`0 4px 6px ${shadowColor}`}
        p={8}
        rounded="xl"
        align="center"
        pos="relative"
        transition="transform 0.3s ease"
        _hover={{ transform: "translateY(-5px)" }}
      >
        <Icon as={icon} w={10} h={10} color="blue.500" mb={4} />
        <Heading fontSize="xl" fontWeight="600" mb={2}>
          {title}
        </Heading>
        <Text textAlign="center" color="gray.600" fontSize="lg">
          {text}
        </Text>
      </Stack>
    </MotionElement>
  );
};

export const RecaptchaHero = () => {
  const navigate = useNavigate();

  return (
    <Box bg={"transparent"} py={20} >
      <Container maxW="7xl" bg={"transparent"}>
        <VStack spacing={16} bg={"transparent"}>
          {/* Hero Section */}
          <Stack spacing={8} align="center" textAlign="center" bg={"transparent"}>
            <MotionElement type="fadeInY">
              <Heading
                as="h1"
                fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                bgGradient="linear(to-r, blue.800, purple.800)"
                bgClip="text"
              >
                Secure Your Website with Intelligent Protection
              </Heading>
            </MotionElement>
            <MotionElement type="fadeInY">
              <Text
                fontSize={{ base: "lg", md: "xl" }}
                color="gray.800"
                maxW="2xl"
              >
                Integrate our advanced Re-CAPTCHA system to protect your website
                from bots while ensuring a seamless experience for real users.
              </Text>
            </MotionElement>
            <MotionElement type="fadeInY">
              <Button
                size="lg"
                colorScheme="blue"
                rightIcon={<FaArrowRight />}
                onClick={() => navigate("/api-reference")}
                _hover={{
                  transform: "translateX(5px)",
                }}
                transition="all 0.3s"
              >
                Get Started with API
              </Button>
            </MotionElement>
          </Stack>

          {/* Features Grid */}
          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={10}
            w="full"
            px={{ base: 4, md: 8 }}
          >
            <Feature
              icon={FaShieldAlt}
              title="Advanced Protection"
              text="Our Re-CAPTCHA system uses AI-powered verification to effectively distinguish between human users and automated bots, providing robust security for your website."
            />
            <Feature
              icon={FaRobot}
              title="Bot Prevention"
              text="Stop spam submissions, prevent automated attacks, and maintain the integrity of your website's data with our intelligent bot detection system."
            />
          </SimpleGrid>

          {/* Integration Preview */}
          <Box
            bg="white"
            p={8}
            rounded="xl"
            shadow="xl"
            w="full"
            maxW="3xl"
            mx="auto"
          >
            <VStack spacing={6}>
              <Heading size="lg" color="gray.700">
                Simple Integration
              </Heading>
              <Text fontSize="lg" color="gray.600" textAlign="center">
                Protect your forms and sensitive actions with just a few lines of
                code. Our API is designed to be developer-friendly and easy to
                implement.
              </Text>
              <Button
                colorScheme="blue"
                size="lg"
                onClick={() => navigate("/api-reference")}
                rightIcon={<FaArrowRight />}
                variant="outline"
              >
                View API Documentation
              </Button>
            </VStack>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};
