import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  SimpleGrid,
  Icon,
  Flex,
  Divider,
  Code,
} from "@chakra-ui/react";
import { MotionElement } from "../../../components/MotionElement";
import { 
  FaCalculator, 
  FaTrophy, 
  FaChartLine, 
  FaLightbulb 
} from "react-icons/fa";

type ScoreCardProps = {
  title: string;
  description: string;
  formula?: string;
  example?: string;
  icon: React.ElementType;
  details?: string[];
}

const ScoreCard = ({ title, description, formula, example, icon, details }: ScoreCardProps) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.700");
  const formulaBg = useColorModeValue("blue.50", "blue.900");
  const textColor = useColorModeValue("gray.600", "gray.300");
  const headingColor = useColorModeValue("gray.800", "white");
  const codeColor = useColorModeValue("gray.50", "gray.700");

  return (
    <Box
      bg={bgColor}
      p={6}
      borderRadius="xl"
      shadow="xl"
      borderWidth={1}
      borderColor={borderColor}
      transition="transform 0.2s"
      _hover={{ transform: "translateY(-4px)" }}
    >
      <Flex align="center" mb={4}>
        <Icon as={icon} w={6} h={6} color="blue.500" mr={3} />
        <Heading as="h3" size="md" color={headingColor}>
          {title}
        </Heading>
      </Flex>
      
      <Text color={textColor} mb={4}>
        {description}
      </Text>

      {formula && (
        <Box bg={formulaBg} p={4} borderRadius="md" mb={4}>
          <Text
            fontFamily="mono"
            fontSize="lg"
            fontWeight="bold"
            textAlign="center"
          >
            {formula}
          </Text>
        </Box>
      )}

      {example && (
        <>
          <Divider my={4} />
          <Text
            fontSize="md"
            color={textColor}
            fontStyle="italic"
          >
            Example:
          </Text>
          <Code
            p={3}
            mt={2}
            display="block"
            whiteSpace="pre-wrap"
            borderRadius="md"
            bg={codeColor}
          >
            {example}
          </Code>
        </>
      )}

      {details && (
        <VStack align="start" spacing={2} mt={4}>
          {details.map((detail, index) => (
            <Text key={index} color={textColor}>
              • {detail}
            </Text>
          ))}
        </VStack>
      )}
    </Box>
  );
};

export const IQScoreExplanation = () => {
  const headingColor = useColorModeValue("gray.800", "white");

  const scoreCards = [
    {
      title: "Score Formula",
      description: "Your IQ score is calculated using a precise mathematical formula that considers your performance relative to the maximum possible score.",
      formula: "IQ Score = (User Score / Maximum Score) × 100",
      icon: FaCalculator,
      details: [
        "User Score: Total points earned",
        "Maximum Score: Total Questions × 5 points",
        "Final score is rounded to nearest whole number"
      ]
    },
    {
      title: "Example Calculation",
      description: "Let's walk through a practical example of how your score is calculated.",
      example: "For 10 questions:\nMaximum Score = 10 × 5 = 50\nIf you score 35 points:\nIQ Score = (35 / 50) × 100 = 70",
      icon: FaLightbulb
    },
    {
      title: "Performance Levels",
      description: "Your score indicates your memory and cognitive performance level.",
      icon: FaTrophy,
      details: [
        "90-100: Exceptional memory recall",
        "70-89: Strong cognitive performance",
        "50-69: Average performance",
        "Below 50: Room for improvement"
      ]
    },
    {
      title: "Improvement Tracking",
      description: "Track your progress over time to see how your cognitive abilities improve with practice.",
      icon: FaChartLine,
      details: [
        "Regular practice improves scores",
        "Track trends in your performance",
        "Set personal improvement goals",
        "Compare with previous attempts"
      ]
    }
  ];

  return (
    <Box py={16}>
      <Container maxW="7xl">
        <MotionElement type="fadeInY">
          <VStack spacing={12}>
            <Box textAlign="center" maxW="3xl" mx="auto">
              <Heading
                as="h1"
                size="2xl"
                mb={6}
                color={headingColor}
                bgGradient="linear(to-r, blue.400, purple.500)"
                bgClip="text"
              >
                Understanding Your IQ Score
              </Heading>
              <Text fontSize="xl" color={useColorModeValue("gray.600", "gray.300")}>
                Learn how we calculate your Memory IQ score and what it means for your
                cognitive abilities.
              </Text>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} w="full">
              {scoreCards.map((card, index) => (
                <MotionElement key={index} type="fadeInY">
                  <ScoreCard {...card} />
                </MotionElement>
              ))}
            </SimpleGrid>
          </VStack>
        </MotionElement>
      </Container>
    </Box>
  );
};
  