import { useEffect } from "react";
import { SOUND_LIST, SoundList } from "../common/utils/constants";

// eslint-disable-next-line no-unused-vars
export const audioList: { [key in SoundList | string]: HTMLAudioElement | null } = {};
let audioListLoaded = false;

export const useSoundPlay = () => {
  useEffect(() => {
    if (audioListLoaded) return;

    SOUND_LIST.forEach((sfx) => {
      audioList[sfx] = new Audio(`/sounds/${sfx}.wav`);
    });
    audioListLoaded = true;
  }, []);

  const playSound = (sfx: string) => {
    const audio = audioList[sfx];
    if (!audio) return;

    const volume = window?.localStorage?.getItem("volume") || "100";
    audio.volume = parseInt(volume) / 100;
    audio.currentTime = 0;
    audio.play();
  };

  return { playSound };
};
