import {
  Box,
  Heading,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { ApiReferenceMenu } from "../nav/ApiReferenceMenu";
import { ApiReferenceSample } from "./apireference/APIReferenceSample";
import { MotionElement } from "../../components/MotionElement";
import ContentCard from "../nav/ContentCard";
import { Highlighter } from "./Highlighter";

export const ApiReference = () => {
  const codeGetRCQString = `/v1/api/re-captcha/{question_type}/{wordLength}/{apikey}`;
  const codePostRCQString = `v1/api/re-captcha/answer-re-captcha`;
const getRecaptchaQuestion = `
@param question_type (string, required): The type of Re-captcha question to retrieve.

Note: Must be one of the following: CHARACTERS, NUMBERS, RANDOM, or COMPLEX

@param wordLength (integer, required): The length of the Re-captcha word to retrieve.

  `;
const AnswerRecaptchaQuestion = `Answer re-captcha question base on question and apikey provided.
  
** Submits an answer to a Re-captcha question.
  
@param string question The Re-captcha question.

@param string answer The answer to the Re-captcha question.

@param string apiKey The API key obtained from https://www.thelastspellingbee.com/api-key

@return a promise that resolves with a boolean indicating whether the answer was correct or not
  `;

  return (
    <ContentCard>
      <ApiReferenceMenu />
      <Box alignContent={"center"}>
        <MotionElement type="fadeInX">
          <Heading>TheLastSpellingBee Re-captcha API</Heading>
        </MotionElement>
        <Box
          mt={10}
          w={{ base: '100%', md: 1200 }}
          fontSize={18}
          rounded={"lg"}
          bg={"#FFF"}
          boxShadow={"lg"}
          p={8}
        >
          <MotionElement type="fadeInY">
            <Text>
              Prevent fraudulent activity, spam, and abuse on your website while
              also minimizing inconvenience to your users.
            </Text>
            <Text fontWeight={"bold"} mt={4}>
              Service:{" "}
            </Text>
            <Text>
              To call this service, we recommend that you use the
             ThelastSpellingBee npm libraries. If your application needs to use
              your own libraries to call this service, use the following
              information when you make the API requests.
            </Text>
            <Text fontWeight={"bold"} mt={4}>
              Service endpoint
            </Text>
            <Text>
              An API service's network address is specified by a base URL called a
              service endpoint. A single service may have multiple service
              endpoints. In our case, the following service endpoint is being
              used, and all URIs about it are relative to this endpoint:
            </Text>
          </MotionElement>
          <TableContainer>
            <Table variant="striped" colorScheme="gray" fontSize={15}>
              <Thead>
                <Tr>
                  <Th>Method</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Get  question</Td>
                  <Td>
                    <Highlighter content={codeGetRCQString} />
                    <Text mt={3}>
                      {" "}
                      Returns a random question base on question type provided.
                    </Text>
                    <Text fontWeight={"bold"} mt={4}>
                      Parameters:{" "}
                    </Text>
                    <Highlighter content={getRecaptchaQuestion} />
                  </Td>
                </Tr>
                <Tr>
                  <Td>Answer captcha</Td>
                  <Td>
                    <Box>
                      <Highlighter content={codePostRCQString} />
                    </Box>
                    <Highlighter content={AnswerRecaptchaQuestion} />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <Box mt={9} textAlign={"left"}>
            <Heading>API Reference Sample</Heading>
            <ApiReferenceSample />
          </Box>
        </Box>
      </Box>
    </ContentCard>
  );
};
