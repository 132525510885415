import * as yup from 'yup';
import { InferType, object, ref, string } from 'yup';

export const LoginSchema = object({
  email: string().required().email("Must be a valid email").label("Email"),
  password: string().required().label("Password"),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type LoginSchema = InferType<typeof LoginSchema>;


export const RegisterSchema = object({
  firstName: string().required().label("First Name"),
  lastName: string().required().label("Last Name"),
  email: string().required().email("Must be a valid email").label("Email"),
  password: string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirmPassword: string()
    .oneOf([ref("password")], "Passwords must match")
    .required("Please confirm your password"),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RegisterSchema = InferType<typeof RegisterSchema>;


export const ForgetPasswordSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ForgetPasswordSchema = InferType<typeof ForgetPasswordSchema>;


export const ResetPasswordValidationSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  email: yup.string().required('email is required'),
  token: yup.string().required('token is required'),

});
export type ResetPasswordValidation = InferType<typeof ResetPasswordValidationSchema>;


export const ContactUsValidationSchema = yup.object().shape({
  subject: yup.string().required('Subject is required'),
  fullName: yup.string().required('Full Name is required'),
  email: yup.string().required('Email is required'),
  message: yup.string().required('Message is required'),
});
export type ContactUs = InferType<typeof ContactUsValidationSchema>;