import * as yup from 'yup';
import { InferType } from 'yup';

export const StartSpellingGameSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required'),
  country: yup.string().required('Country  is required'),
  isPublic: yup.string().oneOf(['true', 'false']).required('Is Public is required'),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type StartSpellingGameSchema = InferType<typeof StartSpellingGameSchema>;

