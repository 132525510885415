import { useAppSelector } from "../redux-action/hooks/useTypedSelector";
import { RootState } from "../redux-action/store";
import { GameState } from "../redux-action/slices/game-slice";

export const useGame = (): GameState => {
  const gameState = useAppSelector((state: RootState) => state.game);
  
  return {
    instruction: gameState.instruction,
    gameScore: gameState.gameScore,
    user: gameState.user,
    questions: gameState.questions,
    isLoading: gameState.isLoading,
    error: gameState.error,
    currentScore: gameState.currentScore,
    timeCompleted: gameState.timeCompleted,
    game: gameState.game,
  };
};
