import { Highlighter } from "../../Highlighter";
import { Text} from "@chakra-ui/react";

export const JavascriptApiSampleCode  = () => {
const codeSampleGetQuestion = `
    /**
    * Generates a Re-captcha question.
    *
    * @param {string} type The type of Re-captcha question to retrieve. 
    * type: Must be one of the following: AdvancedCharacters, AdvancedNumbers, AdvancedCharactersAndNumbers
    * @param {integer} wordLength The length of the Re-captcha word (default: 3)
    * @param {string} apiKey Your API key
    * @return {Promise} A promise that resolves with the Re-captcha question data
    */
    
    async function generateReCaptcha(type, wordLength, apiKey) {
      try {
        const response = await fetch('https://thelastspellingbee-api.onrender.com/re-captcha/{type}/{wordLength}/{apiKey}', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type,
            wordLength
          })
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }

    // Example usage:
    const question = await generateReCaptcha('AdvancedCharacters', 3, 'your-api-key');
    console.log(question);
    // Output: [{ _id: '1', question: 'A_C', type: 'AdvancedCharacters', instructions: '...', hiddenValue: 'B' }]
    `;
  const codeSampleAnswerQuestion = `
    /**
     * Verifies a Re-captcha answer.
     *
     * @param {string} type The type of captcha (must match the question type)
     * @param {string} question The captcha question
     * @param {string} answer User's answer
     * @param {string} hiddenValue The hidden value from the question (optional)
     * @param {string} apiKey Your API key
     * @return {Promise} A promise that resolves with the verification result
     */

    async function checkReCaptchaAnswer(type, question, answer, hiddenValue, apiKey) {
      try {
        const response = await fetch('https://thelastspellingbee-api.onrender.com/re-captcha/check-answer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            type,
            question,
            answer,
            hiddenValue,
            apiKey
          })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }

    // Example usage:
    const result = await checkReCaptchaAnswer(
      'AdvancedCharacters',
      'A_C',
      'B',
      'B',
      'your-api-key'
    );
    console.log(result);
    // Output: { status: true, verified: true, timestamp: '2024-...', token: 'xxx' }
    `;

    return(
       <>
            <Text fontWeight={"bold"} mt={9} mb={1}>Get Question</Text>
            <Highlighter content={codeSampleGetQuestion} />
            <Text fontWeight={"bold"} mt={9} mb={1}>Answer Question</Text>
            <Highlighter content={codeSampleAnswerQuestion} />
       </>
    )
}