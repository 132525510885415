import { 
  Box, 
  Container, 
  SimpleGrid, 
  Spinner, 
  Heading,
  Text,
  VStack,
  Flex,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useApiService } from "../../redux-action/api.service";
import { useEffect, useState } from "react";
import { Score } from "../../types/score-types";
import { useUser } from "../../hooks/use-user";
import ContentCard from "../nav/ContentCard";
import { Background } from "../../common/background/Background";
import { ScoreCard } from "../spelling/IQScore/ScoreCard";
import { CertificateModal } from "../spelling/IQScore/CertificateModal";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

export const MyIQScores = () => {
  const { user } = useUser();
  const api = useApiService();
  const [scores, setScores] = useState<Score[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [playerResult, setPlayerResult] = useState<Score>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchScores = async () => {
      try {
        setIsLoading(true);
        const response = await api.service(`game-scores/user/${user.id}`).find();
        setScores(response);
      } catch (error) {
        setError(new Error("Failed to load your scores. Please try again later."));
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (user?.id) {
      fetchScores();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" minH="50vh">
        <Spinner size="xl" color="blue.500" thickness="4px" />
      </Flex>
    );
  }

  return (
    <ContentCard bg="transparent">
      <Background />
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8}>
          {/* Header Section */}
          <Box textAlign="center" w="full">
            <Heading
              as="h1"
              size="2xl"
              mb={4}
              color="white"
              letterSpacing="tight"
            >
              My Memory IQ Scores
            </Heading>
            <Text color="gray.200" fontSize="lg" maxW="2xl" mx="auto">
              Track your progress and view your achievements
            </Text>
          </Box>

          {error && (
            <Alert status="error" borderRadius="md">
              <AlertIcon />
              {error.message}
            </Alert>
          )}

          {/* Scores Grid */}
          <SimpleGrid
            columns={{ base: 1, lg: 1 }}
            spacing={6}
            w="full"
            px={{ base: 4, md: 8 }}
          >
            {scores?.map((score, index) => (
              <MotionBox
                key={score.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <ScoreCard
                  score={score}
                  onViewCertificate={(score) => {
                    setPlayerResult(score);
                    setIsModalOpen(true);
                  }}
                />
              </MotionBox>
            ))}
          </SimpleGrid>

          {/* Empty State */}
          {!isLoading && scores.length === 0 && (
            <Box 
              textAlign="center" 
              p={8} 
              bg="whiteAlpha.100" 
              borderRadius="xl"
              backdropFilter="blur(10px)"
              w="full"
            >
              <Text color="gray.200" fontSize="lg">
                You haven't taken any Memory IQ tests yet. 
                Start a test to see your scores here!
              </Text>
            </Box>
          )}
        </VStack>
      </Container>

      <CertificateModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        score={playerResult ?? ({} as Score)}
      />
    </ContentCard>
  );
};
