import { FC } from "react";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useToast,
  Heading,
  Box,
  MenuItem,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Game } from "../../types/game-type";
import { GameSchema } from "./Schema";
import { CreateGameForm } from "../../components/CreateGameForm";
import { useApiService } from "../../redux-action/api.service";
import { useGames } from "../../context/GamesContext";
import { QuestionType } from "../../types/questions.type";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux-action/store";
import { updateUserCredits } from "../../redux-action/slices/auth-slice";
import { useUser } from "../../hooks/use-user";

type EditGameProps = {
  game: Game;
};

export const EditGame: FC<EditGameProps> = ({ game }) => {
  const { user } = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const api = useApiService();
  const dispatch = useDispatch<AppDispatch>();
  const { refreshGames } = useGames();
  

  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting, errors },
  } = useForm<GameSchema>({
    resolver: yupResolver(GameSchema),
    defaultValues: {
      name: game.name,
      gameCategory: game.gameCategory,
      questionType: game.questionType as QuestionType,
      wordLength: game.wordLength,
      numberOfQuestions: game.numberOfQuestions,
      gameTime: game.gameTime,
      expiryDate: new Date(game.expiryDate).toISOString().split("T")[0],
      expectedUsers: game.expectedUsers || 1,
    },
  });


  const onSubmitHandler = async (data: GameSchema) => {
    try {
      // Check if user is trying to decrease expectedUsers
      if (Number(data.expectedUsers) < game.expectedUsers) {
        toast({
          title: "Cannot decrease expected users",
          description: "You can only increase the number of expected users",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // Calculate additional users only if expectedUsers increased
      const additionalUsers = Number(data.expectedUsers) - game.expectedUsers;
      
      // Check if user has enough credits for additional users
      if (additionalUsers > 0 && (user.credit ?? 0) < additionalUsers) {
        toast({
          title: "Insufficient credits",
          description: `You need ${additionalUsers} more credits to add ${additionalUsers} users`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const gameData = {
        ...data,
        expiryDate: new Date(data.expiryDate).toISOString(),
        expectedUsers: Number(data.expectedUsers),
      };

      const response = await api.service("games").patch(game?.id?.toString() ?? "", gameData);

      if (response) {
        // Only update credits if expectedUsers increased
        if (additionalUsers > 0) {
          const updatedCredits = (user.credit ?? 0) - additionalUsers;
          dispatch(updateUserCredits(updatedCredits));
        }

        toast({
          title: "Game Updated",
          description: additionalUsers > 0 
            ? `Game updated successfully. ${additionalUsers} credits deducted.`
            : "Game updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onClose();
        await refreshGames();
      }
    } catch (error: any) {
      toast({
        title: "Error updating game",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <MenuItem _hover={{ bg: "gray.50" }} cursor="pointer" onClick={onOpen}>
        Edit
      </MenuItem>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="lg">Update Memory IQ Test</Heading>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={6}>
            <Box as="form" onSubmit={handleSubmit(onSubmitHandler)}>
              <CreateGameForm
                register={register}
                errors={errors}
                control={control}
                isSubmitting={isSubmitting}
                actionType="edit"
                expectedUsers={game.expectedUsers}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
