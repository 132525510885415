import { 
  Flex, 
  Icon, 
  Link, 
  useColorModeValue,
  Button,
  HStack,
  Divider,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import { FiHome, FiFile, FiRadio, FiPackage } from "react-icons/fi";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

export const ApiReferenceMenu = () => {
  const location = useLocation();
  const bg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const activeColor = useColorModeValue("blue.500", "blue.300");
  const hoverBg = useColorModeValue("gray.50", "gray.700");
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  const navItems = [
    { title: "About", to: "/about-recaptcha", Icon: FiHome },
    { title: "API Reference", to: "/api-reference", Icon: FiFile },
    { title: "NPM Package", to: "/npm/the-last-spelling-bee-re-captcha", Icon: FiPackage },
    { title: "Get API Key", to: "/auth/signin", Icon: FiRadio },
  ];

  return (
    <Box
      as="nav"
      position="sticky"
      top={0}
      zIndex={1000}
      bg={bg}
      borderBottom="1px solid"
      borderColor={borderColor}
      shadow="sm"
      mb={10}
      mt={-10}
    >
      <Flex
        maxW="7xl"
        mx="auto"
        px={4}
        py={3}
        justify="center"
        align="center"
        flexWrap="wrap"
      >
        <HStack
          spacing={isSmallScreen ? 2 : 4}
          justify="center"
          align="center"
          flexWrap="wrap"
        >
          {navItems.map((item, index) => (
            <Box key={item.to}>
              <Link
                as={ReactRouterLink}
                to={item.to}
                _hover={{ textDecoration: "none" }}
              >
                <Button
                  variant="ghost"
                  size={isSmallScreen ? "sm" : "md"}
                  leftIcon={<Icon as={item.Icon} />}
                  color={location.pathname === item.to ? activeColor : "inherit"}
                  bg={location.pathname === item.to ? hoverBg : "transparent"}
                  _hover={{
                    bg: hoverBg,
                    transform: "translateY(-1px)",
                  }}
                  transition="all 0.2s"
                >
                  {item.title}
                </Button>
              </Link>
              {index < navItems.length - 1 && !isSmallScreen && (
                <Divider orientation="vertical" height="20px" mx={4} display="inline-block" />
              )}
            </Box>
          ))}
        </HStack>
      </Flex>
    </Box>
  );
};
